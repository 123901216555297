export const getRouteWithId = (route, id) => route.replace(':id', id)

export const getRouteWithRegionId = (route, id) =>
    route.replace(':regionId', id)

export const getRouteWithUserId = (route, id) => route.replace(':userId', id)

export const getRouteWithSlug = (route, slug) => route.replace(':slug', slug)

export const getRouteWithTreatmentId = (route, id, clinicId) =>
    route.replace(':id', clinicId).replace(':treatmentId', id)

export const getRouteWithPractitionerId = (route, id, clinicId) =>
    route.replace(':id', clinicId).replace(':practitionerId', id)

export const getRouteWithCategoryId = (route, id, clinicId) =>
    route.replace(':id', clinicId).replace(':categoryId', id)
