import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ResetFormValidators } from '@containers/login/configurations/LoginValidationConfig'
import { translate } from '@utils/translationUtils'
import { Form } from '@shared/components/Form/Form'
import { AuthLayout } from '@shared/components/AuthLayout/AuthLayout'
import { ForgotPasswordLink } from '@containers/login/styledComponents'
import { Routes } from '@shared/constants/routes'
import { resetPassword } from '@store/reducers/authorization'
import { showSuccessMessage, showErrorMessage } from '@utils/notificationUtils'
import { ResetFormConfig } from './configurations/ResetFormConfig'
import { useHistory } from 'react-router'
import { getQueryParams } from '@utils/urlUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'

const ResetPassword = ({ dispatch }) => {
    const history = useHistory()

    const queryParams = history.location.search
    const userEmail = getQueryParams(queryParams, 'email')

    const {
        watch,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ResetFormValidators(userEmail)),
    })

    const onSubmit = async data => {
        try {
            data.token = getQueryParams(queryParams, 'token')
            data.email = userEmail

            await dispatch(resetPassword(data))

            history.push(Routes.login)
            showSuccessMessage('notification.passwordChanged')
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <AuthLayout formTitle={translate('resetPassword')}>
            <Form
                {...{
                    errors,
                    control,
                    buttonText: translate('form.confirm'),
                    onConfirm: handleSubmit(onSubmit),
                    formConfig: ResetFormConfig,
                }}
                fullWidthForm
            />

            <ForgotPasswordLink to={Routes.login}>
                {translate('form.login')}
            </ForgotPasswordLink>
        </AuthLayout>
    )
}

export default withConnectedStore(ResetPassword)
