import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { RoutesConfig } from './configurations/RoutesConfig'
import { withGlobalConfigs } from 'hocs/withGlobalConfigs'
import { Routes } from '@shared/constants/routes'
import { PrivateRoute } from '@shared/components/PrivateRoute/PrivateRoute'

const App = () => (
    <BrowserRouter>
        <Switch>
            {RoutesConfig.map(({ routeParams, component, isPrivate }) => {
                const Component = component
                const AppRoute = isPrivate ? PrivateRoute : Route

                return (
                    <AppRoute
                        {...routeParams}
                        key={routeParams.path}
                        component={Component}
                    />
                )
            })}

            <Redirect from="/" to={Routes.clinics} exact />
        </Switch>
    </BrowserRouter>
)

export default withGlobalConfigs(App)
