export const deleteEmptyValues = obj => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key]

            if (!value) {
                delete obj[key]
            }
        }
    }

    return obj
}
