import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Row } from '@shared/components/Table/Raw'
import { translate } from '@utils/translationUtils'
import { getRouteWithTreatmentId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { StyledTitle } from '@containers/clinics/styledComponents'
import { Title } from '@containers/clinics/components/Title'
import { updateTreatmentsOrder } from '@store/reducers/treatments'
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
} from '@dnd-kit/core'
import {
    SortableContext,
    useSortable,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DotsNine } from '../../../../node_modules/phosphor-react/dist/index'
import { Toggle } from '../index'

const SortableItem = ({
    treatment,
    clinicId,
    categoryId,
    dispatch,
    active,
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: treatment.id })

    const [isEnabled, setIsEnabled] = useState(
        active === 1 && treatment.status === 'active'
    )

    useEffect(() => {
        setIsEnabled(active === 1 && treatment.status === 'active')
    }, [active, treatment.status])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        marginBottom: '8px',
        backgroundColor: isEnabled ? '#fff' : '#e0e0e0',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: isEnabled ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
        opacity: isEnabled ? 1 : 0.5,
        cursor: isEnabled ? 'grab' : 'not-allowed',
    }

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Row
                firstCellContent={
                    <Box display="flex" alignItems="center">
                        <DotsNine size={16} className="mr-2" />
                        <Title text={treatment.title} isSemiBold />
                    </Box>
                }
                thirdCellContent={
                    <StyledTitle regular right>
                        {`${treatment.price} kroner`}
                    </StyledTitle>
                }
                forthCellContent={
                    <div className="flex items-center gap-3 pl-2">
                        <Toggle
                            isEnabled={isEnabled}
                            setIsEnabled={setIsEnabled}
                            id={treatment.id}
                            type="treatment"
                            clinicId={clinicId}
                            categoryId={categoryId}
                            dispatch={dispatch}
                            disabled={active !== 1}
                        />
                        <UnderlinedLink
                            text={translate('edit')}
                            url={getRouteWithTreatmentId(
                                Routes.treatmentsEdit,
                                treatment.id,
                                clinicId
                            )}
                            rightAlign="right"
                        />
                    </div>
                }
                marginTop={1}
                forthCellWidth={12}
            />
        </div>
    )
}

export const TreatmentsContent = ({
    treatments = [],
    clinicId,
    categoryId,
    dispatch,
    active,
}) => {
    const [localTreatments, setLocalTreatments] = useState([])

    useEffect(() => {
        const sortedTreatments = [...treatments].sort(
            (a, b) => a.order - b.order
        )
        setLocalTreatments(sortedTreatments)
    }, [treatments])

    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
    )

    const handleDragEnd = event => {
        const { active, over } = event
        if (!over || active.id === over.id) return

        const oldIndex = localTreatments.findIndex(
            treatment => treatment.id === active.id
        )
        const newIndex = localTreatments.findIndex(
            treatment => treatment.id === over.id
        )

        const newOrder = arrayMove(localTreatments, oldIndex, newIndex)
        setLocalTreatments(newOrder)
        updateOrder(newOrder)
    }

    const updateOrder = updatedTreatments => {
        const orders = updatedTreatments.reduce((acc, treatment, index) => {
            acc[treatment.id] = index + 1
            return acc
        }, {})

        const payload = {
            clinic_id: clinicId,
            category_id: categoryId,
            orders: orders,
        }

        dispatch(updateTreatmentsOrder(payload, clinicId, categoryId))
    }

    return (
        <DndContext
            sensors={active === 1 ? sensors : []}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={localTreatments.map(treatment => treatment.id)}
                strategy={verticalListSortingStrategy}
            >
                {localTreatments.map(treatment => {
                    return (
                        <SortableItem
                            key={treatment.id}
                            treatment={treatment}
                            clinicId={clinicId}
                            categoryId={categoryId}
                            dispatch={dispatch}
                            active={active}
                        />
                    )
                })}
            </SortableContext>
        </DndContext>
    )
}
