import styled from '@emotion/styled'
import { Colors } from '@shared/constants/colors'
import { Link } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import { appBarHeight } from '@shared/constants/styleConstants'

const LoginContainer = styled.div`
    min-height: 100vh;
    background-image: linear-gradient(${Colors.primary}, ${Colors.secondary});
    display: flex;
`

const LoginFormWrapper = styled.div`
    background-color: ${Colors.white};
    width: 450px;
    margin: auto;
    padding: 15px;
    border-radius: 8px;
`

const FormTitleText = styled.h5`
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 10px;
`

const ForgotPasswordLink = styled(Link)`
    display: flex;
    justify-content: center;
    margin: 13px 0 5px;
    color: ${Colors.gray[300]};
`

const LoginToolbar = styled(Toolbar)`
    background-color: ${Colors.gray[100]};
    border-bottom: 2px solid ${Colors.gray[150]};
    height: ${appBarHeight}px;

    &.MuiToolbar-root {
        position: fixed;
        top: 0;
        left: 0;
    }

    &.MuiToolbar-gutters {
        padding-left: 35px;
        padding-right: 30px;
    }
`

export {
    LoginToolbar,
    FormTitleText,
    LoginContainer,
    LoginFormWrapper,
    ForgotPasswordLink,
}
