import { translate } from '@utils/translationUtils'
import { string } from 'yup'

export class TreatmentFormDataProvider {
    generateFormData = data => {
        const {
            price,
            title,
            duration,
            category,
            show_price,
            opus_dental_id,
            long_description,
            short_description,
        } = data

        const formattedData = {
            price,
            title,
            duration,
            show_price,
            opus_dental_id,
            long_description,
            short_description,
            status: 'active',
            tips: this.getTipsArray(data),
        }

        if (category.value) {
            formattedData.treatment_category_id = category.value
        }

        return formattedData
    }

    getTipsArray = data => {
        const tips = []

        for (let i = 0; i < 10; i++) {
            const tipHeader = 'tipHeader'
            const tipText = 'tipText'

            const tipHeaderKey = `${tipHeader}${i || ''}`
            const tipTextKey = `${tipText}${i || ''}`

            if (data[tipHeaderKey] || data[tipTextKey]) {
                const tip = {
                    title: data[tipHeaderKey],
                    description: data[tipTextKey],
                }
                const tipId = data[`${tipHeaderKey}id`]

                if (tipId === 'number') {
                    tip.id = tipId
                }
                tips.push(tip)
            }
        }

        return tips
    }

    addTipsToValidators = (validators, tips = []) => {
        tips.forEach(
            ({ fieldProps }) =>
                (validators[fieldProps.name] = string().required(
                    'This field is required'
                ))
        )

        return validators
    }

    removeTipsFromValidators = (validators, tips = []) => {
        tips.forEach(({ fieldProps }) => delete validators[fieldProps.name])

        return validators
    }

    removeTipsFromValidatorsByNames = (validators, tipNames = []) => {
        tipNames.forEach(name => delete validators[name])

        return validators
    }

    formatTipsFromBE = (tips = []) => {
        const formattedTips = []

        tips.forEach((tip, index) => {
            const currentNumber = index + 1
            const nameIndex = currentNumber === 1 ? '' : currentNumber

            if (tip.title) {
                formattedTips.push({
                    id: tip.id,
                    isTip: true,
                    label: translate(`treatment.tipHeader${nameIndex}`),
                    tipNumber: currentNumber,
                    fieldProps: {
                        name: `tipHeader${nameIndex}`,
                        placeholder: 'treatment.enterTipHeader',
                        required: true,
                    },
                })
            }

            formattedTips.push({
                id: tip.id,
                isTip: true,
                label: translate(`treatment.tipText${nameIndex}`),
                tipNumber: currentNumber,
                fieldProps: {
                    name: `tipText${nameIndex}`,
                    placeholder: 'treatment.enterTipText',
                    required: true,
                },
            })
        })

        return formattedTips
    }

    setTipsToDefaultFormValues = (tips = []) => {
        const defaultTipsValues = {}

        tips.forEach((tip, index) => {
            const currentNumber = index + 1
            const nameIndex = currentNumber === 1 ? '' : currentNumber

            defaultTipsValues[`tipHeader${nameIndex}`] = tip.title
            defaultTipsValues[`tipText${nameIndex}`] = tip.description
        })

        return defaultTipsValues
    }

    generateFormDataWithTipIds = (formData, tips = []) => {
        tips.forEach(tip => {
            const fieldName = tip.fieldProps.name
            if (formData[fieldName]) {
                formData[`${fieldName}id`] = tip.id
            }
        })

        const data = this.generateFormData(formData)

        return data
    }
}
