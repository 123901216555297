import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ForgotFormValidators } from '@containers/login/configurations/LoginValidationConfig'
import { translate } from '@utils/translationUtils'
import { Form } from '@shared/components/Form/Form'
import { AuthLayout } from '@shared/components/AuthLayout/AuthLayout'
import { ForgotFormConfig } from './configurations/ForgotFormConfig'
import { ForgotPasswordLink } from '@containers/login/styledComponents'
import { Routes } from '@shared/constants/routes'
import { forgotPassword } from '@store/reducers/authorization'
import { showSuccessMessage, showErrorMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { useHistory } from 'react-router'

const ForgotPassword = ({ dispatch }) => {
    const history = useHistory()
    
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ForgotFormValidators),
    })

    const onSubmit = async data => {
        try {
            await dispatch(forgotPassword(data))
            showSuccessMessage('notification.successEmailSent')

            history.push(Routes.login)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <AuthLayout formTitle={translate('forgotPassword')}>
            <Form
                {...{
                    errors,
                    control,
                    buttonText: translate('form.confirm'),
                    onConfirm: handleSubmit(onSubmit),
                    formConfig: ForgotFormConfig,
                }}
                fullWidthForm
            />

            <ForgotPasswordLink to={Routes.login}>
                {translate('form.login')}
            </ForgotPasswordLink>
        </AuthLayout>
    )
}

export default withConnectedStore(ForgotPassword)
