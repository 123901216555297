import styled from '@emotion/styled'
import { Button, withStyles } from '@material-ui/core'
import { Colors } from '@shared/constants/colors'

export const StyledPrimaryButton = withStyles({
    root: {
        width: '100%',
        boxShadow: 'none',
        fontSize: 16,
        padding: 5,
        backgroundColor: Colors.primary,
        textTransform: 'initial',
        borderRadius: 8,
        height: 38,
        color: Colors.white,
        '&:hover': {
            backgroundColor: Colors.primary,
        },
        '&:disabled': {
            backgroundColor: Colors.gray[200],
            color: Colors.white,
            cursor: 'no-drop',
        },
    },
})(Button)

export const UnderlinedBtn = styled.button`
    text-decoration: underline;
    color: ${Colors.gray[300]};
    padding: 0;
    border: none;
    font-size: 16px;
    background-color: transparent;
    cursor: pointer;
`
