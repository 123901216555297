import Chain from '@containers/chain/index'
import Clinics from '@containers/clinics/index'
import CreateChain from '@containers/createChain/index'
import EditChain from '@containers/editChain/index'
import { Routes } from '@shared/constants/routes'

export const chainRoutes = [
    {
        routeParams: {
            path: Routes.clinics,
            exact: true,
        },
        component: Clinics,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.createChain,
        },
        component: CreateChain,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.editChain,
        },
        component: EditChain,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.viewChain,
        },
        component: Chain,
        isPrivate: true,
    },
]
