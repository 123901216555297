import Categories from '@containers/categories/index'
import CreateCategory from '@containers/createCategory/index'
import CreateTreatment from '@containers/createTreatment/index'
import EditCategory from '@containers/editCategory/index'
import EditTreatment from '@containers/editTreatment/index'
import Treatments from '@containers/treatments/index'
import { Routes } from '@shared/constants/routes'

export const treatmentRoutes = [
    {
        routeParams: {
            path: Routes.treatmentsCreate,
        },
        component: CreateTreatment,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.treatmentsEdit,
            exact: true,
        },
        component: EditTreatment,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.treatmentsCategoryCreate,
        },
        component: CreateCategory,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.treatmentsCategoryEdit,
        },
        component: EditCategory,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.treatmentsCategories,
        },
        component: Categories,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.treatments,
            exact: true,
        },
        component: Treatments,
        isPrivate: true,
    },
]
