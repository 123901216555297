import React from 'react'
import { useHistory } from 'react-router'
import { StyledLink } from '@shared/components/Links/styledComponents'
import { Title } from '@containers/clinics/components/Title'
import { HeaderWrapper } from './styledComponents'
import { UnderlinedButton } from '../Buttons/UnderlinedButton'

export const SidebarPageHeader = ({
    title,
    navigateTo,
    navBtnText,
    hiddenButton = false,
}) => {
    const { goBack } = useHistory()

    return (
        <HeaderWrapper>
            <Title text={title} />

            {!hiddenButton &&
                (navigateTo ? (
                    <StyledLink to={navigateTo}>{navBtnText}</StyledLink>
                ) : (
                    <UnderlinedButton
                        onClick={goBack}
                        translationKey="cancel"
                    />
                ))}
        </HeaderWrapper>
    )
}
