import styled from '@emotion/styled'

const TableColumn = styled.div`
    width: ${props => props.width}%;
`

const TableContainer = styled.div`
    min-height: 80vh;
    & .MuiPaper-root {
        box-shadow: none;
    }
    & .MuiIconButton-edgeEnd {
        position: absolute;
        right: -35px;
    }
    & .MuiAccordionSummary-root {
        border-bottom: ${({ withoutunderline }) =>
            withoutunderline ? 'none' : '1px solid #707070'};
        min-height: 60px;
    }
    & .MuiButtonBase-root {
        padding: 0;
    }

    & .MuiAccordionDetails-root {
        padding-right: 0;
        padding-left: 0;
    }
`

const TableContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`

export { TableColumn, TableContainer, TableContentContainer }
