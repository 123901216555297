import React, { useEffect, useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { withConnectedStore } from '@hocs/withConnectedStore'
import {
    getPractitioners,
    updatePractitionerOrder,
} from '@store/reducers/practitioners'
import { useParams } from 'react-router'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { getRouteWithId, getRouteWithPractitionerId } from '@utils/routeUtils'
import {
    TableContainer,
    TableContentContainer,
} from '@shared/components/Table/styledComponents'
import { Row } from '@shared/components/Table/Raw'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { translate } from '@utils/translationUtils'
import { Routes } from '@shared/constants/routes'
import Box from '@material-ui/core/Box'
import { Title } from '@containers/clinics/components/Title'
import { StyledTitle } from '@containers/clinics/styledComponents'
import { WithStatusDot } from '@shared/components/StatusDot/WithStatusDot'
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
} from '@dnd-kit/core'
import {
    SortableContext,
    useSortable,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

// Sortable Row Component for each practitioner
const SortableRow = ({ practitioner, clinicId }) => {
    const { id, name, title, image, status } = practitioner
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: '100%',
        marginBottom: '16px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        backgroundColor: '#fff',
    }

    return (
        <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Row
                firstCellContent={
                    <WithStatusDot status={status}>
                        <Title
                            text={name}
                            imageSrc={image}
                            isRoundImage
                            isSemiBold
                        />
                    </WithStatusDot>
                }
                secondCellContent={<StyledTitle regular>{title}</StyledTitle>}
                forthCellContent={
                    <UnderlinedLink
                        text={translate('edit')}
                        url={getRouteWithPractitionerId(
                            Routes.practitionersEdit,
                            id,
                            clinicId
                        )}
                        rightAlign="right"
                    />
                }
                marginTop={1}
            />
        </Box>
    )
}

const Practitioners = ({ store, dispatch }) => {
    const { id } = useParams()
    const { practitioners, loading } = store.practitioners
    const [localPractitioners, setLocalPractitioners] = useState([])

    useEffect(() => {
        dispatch(getPractitioners(id))
    }, [dispatch, id])

    useEffect(() => {
        if (practitioners?.length) {
            const sortedPractitioners = practitioners.sort(
                (a, b) => a.order - b.order
            )
            setLocalPractitioners(sortedPractitioners)
        }
    }, [practitioners])

    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
    )

    const handleDragEnd = event => {
        const { active, over } = event
        if (!over || active.id === over.id) return

        const oldIndex = localPractitioners.findIndex(
            practitioner => practitioner.id === active.id
        )
        const newIndex = localPractitioners.findIndex(
            practitioner => practitioner.id === over.id
        )

        const newOrder = arrayMove(localPractitioners, oldIndex, newIndex)
        setLocalPractitioners(newOrder)

        const orders = newOrder.reduce((acc, practitioner, index) => {
            acc[practitioner.id] = index + 1
            return acc
        }, {})

        dispatch(updatePractitionerOrder(id, orders))
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('practitioner.practitioners')}
                navigateTo={getRouteWithId(Routes.practitionersCreate, id)}
                navBtnText={translate('addNew')}
            />

            {!!localPractitioners.length && (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <TableContainer>
                        <SortableContext
                            items={localPractitioners.map(p => p.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <TableContentContainer>
                                {localPractitioners.map(practitioner => (
                                    <SortableRow
                                        key={practitioner.id}
                                        practitioner={practitioner}
                                        clinicId={id}
                                    />
                                ))}
                            </TableContentContainer>
                        </SortableContext>
                    </TableContainer>
                </DndContext>
            )}
        </SidebarLayout>
    )
}

export default withConnectedStore(Practitioners)
