import React, { useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { Form } from '@shared/components/Form/Form'
import { ImagePicker } from '@shared/components/ImagePicker/ImagePicker'
import {
    updateClinic,
    deleteClinic,
    getClinicInfo,
} from '@store/reducers/clinics'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import { Routes } from '@shared/constants/routes'
import Box from '@material-ui/core/Box'
import {
    getClinicFormConfig,
    hoursOptions,
    clinicTypeOptions,
    noRegionOption,
    ClinicTipsFormConfig,
} from '@containers/createClinic/configurations/CreateClinicFormConfig'
import { findSelectOptionByValue, getTrueFalseOptions } from '@utils/arrayUtils'
import { getChainRegions } from '@store/reducers/regions'
import { checkAccess } from '@utils/userUtils'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'
import { UserRolesEnum } from '@enums/UserRolesEnum'
import { TrueFalseEnum } from '@enums/TrueFalseEnum'
import { withClinicTips } from '@hocs/withClinicTips'
import { ClinicDataProvider } from 'providers/clinic/ClinicDataProvider'
import { CreateTreatmentFromSection } from '@containers/createTreatment/components/CreateTreatmentFromSection'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'

const EditClinic = ({
    reset,
    store,
    errors,
    control,
    register,
    dispatch,
    removeTip,
    addNewTip,
    clinicTips,
    needShowTips,
    handleSubmit,
    setClinicTips,
    maxTipsLength,
}) => {
    const clinicDataProvider = new ClinicDataProvider()

    const { clinic, loading } = store.clinics
    const { regions } = store.regions
    const user = store.auth.user

    const {
        zip,
        lat,
        long,
        name,
        city,
        tips,
        type,
        email,
        phone,
        image,
        group,
        region,
        country,
        address1,
        address2,
        penalty_fee,
        opus_clinic_id,
        cancellation_time,
    } = clinic

    const selectedTime = findSelectOptionByValue(
        hoursOptions,
        cancellation_time
    )

    const selectedType = findSelectOptionByValue(clinicTypeOptions, type)

    const defaultValues = {
        lat,
        zip,
        name,
        long,
        city,
        email,
        phone,
        image,
        group: group || '',
        country,
        address1,
        address2,
        penalty_fee: penalty_fee || '',
        opus_clinic_id: opus_clinic_id || '',
        type: selectedType,
        cancellation_time: selectedTime,
        showTips: findSelectOptionByValue(
            getTrueFalseOptions(),
            tips?.length ? TrueFalseEnum.YES : TrueFalseEnum.NO
        ),
    }

    if (region && region.id) {
        defaultValues.region_id = { value: region.id, label: region.name }
    } else {
        defaultValues.region_id = noRegionOption
    }

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isSizeError, setSizeError] = useState(false)

    const history = useHistory()
    const { id } = useParams()

    const getClinicInformation = async () => {
        const { value } = await dispatch(getClinicInfo(id))

        const isRegUser = user?.roles[0] === UserRolesEnum.REGULAR_USER

        if (!isRegUser) {
            dispatch(getChainRegions(value.data.chain?.slug))
        }
    }

    useEffect(() => {
        getClinicInformation()
    }, [])

    useEffect(() => {
        const formattedTips = clinicDataProvider.formatTipsFromBE(tips)
        const defaultTipsValues = clinicDataProvider.setTipsToDefaultFormValues(
            tips,
            formattedTips
        )

        setSelectedImage(image)
        setSelectedFile(image)
        reset({ ...defaultValues, ...defaultTipsValues })

        if (formattedTips.length) {
            setClinicTips(formattedTips)
        } else {
            setClinicTips(ClinicTipsFormConfig.tips)
        }
    }, [name])

    const onSubmit = async data => {
        try {
            const isSameFile = image === selectedImage

            if (!isSameFile) {
                data.file = selectedFile
            }

            const formData = clinicDataProvider.generateFormDataWithTipIds(
                data,
                clinicTips
            )

            await dispatch(updateClinic(formData, clinic.id))
            showSuccessMessage('notification.clinicUpdated')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const onClinicDelete = async () => {
        try {
            await dispatch(deleteClinic(clinic.id))
            showSuccessMessage('notification.clinicDeleted')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const canDeleteClinic = checkAccess(
        user?.permissions,
        UserPermissionsEnum.CLINIC_CREATE
    )

    const formConfig = getClinicFormConfig(regions)

    if (!clinic.id) {
        return <SidebarLayout loading={loading} />
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('clinic.editClinic')} />

            <Form
                {...{
                    errors,
                    control,
                    register,
                    formConfig,
                    isDisabled: isSizeError,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                }}
            >
                <Box>
                    <CreateTreatmentFromSection
                        {...{ errors, control, register }}
                        config={ClinicTipsFormConfig.showTips}
                    />

                    {needShowTips && (
                        <CreateTreatmentFromSection
                            {...{ errors, control, register }}
                            config={clinicTips}
                            removeTip={removeTip}
                            isClinicTip
                        />
                    )}

                    {needShowTips && clinicTips.length !== maxTipsLength && (
                        <Box mt={2} width="150px">
                            <PrimaryButton
                                text={translate('treatment.addTip')}
                                onClick={addNewTip}
                                disabled={false}
                            />
                        </Box>
                    )}
                </Box>

                <ImagePicker
                    {...{
                        label: 'form.logo',
                        isSizeError,
                        setSizeError,
                        selectedImage,
                        setSelectedFile,
                        setSelectedImage,
                    }}
                />
            </Form>

            {canDeleteClinic && (
                <Box marginTop={2}>
                    <UnderlinedButton
                        onClick={onClinicDelete}
                        translationKey={'clinic.deleteClinic'}
                    />
                </Box>
            )}
        </SidebarLayout>
    )
}

export default withConnectedStore(withClinicTips(EditClinic))
