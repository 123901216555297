import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { getUserDetails } from '@store/reducers/users'
import { loadChains } from '@store/reducers/chains'
import { UserRolesEnum } from '@enums/UserRolesEnum'
import { getChainClinics } from '@store/reducers/clinics'
import { useHistory, useParams } from 'react-router'
import { Title } from '@containers/clinics/components/Title'
import { EditUserForm } from './components/EditUserForm'
import { DeleteUserBlock } from './components/DeleteUserBlock'

const EditUser = ({ dispatch, store }) => {
    const history = useHistory()
    const { userId } = useParams()

    const { auth, chains, users } = store

    const loading = chains.loading || users.loading
    const userRole = auth.user.roles[0]

    const targetUserRole = users.userDetails.roles[0]

    const isChainAdmin = userRole === UserRolesEnum.CHAIN_ADMIN
    const isSuperAdmin = userRole === UserRolesEnum.SUPER_ADMIN

    const isRegularUser = targetUserRole === UserRolesEnum.REGULAR_USER
    const isChainAdminUser = targetUserRole === UserRolesEnum.CHAIN_ADMIN

    useEffect(() => {
        if (!users.userDetails.id) {
            dispatch(getUserDetails(userId))
        }

        if (isRegularUser) {
            const chainSlug = users.userDetails.chains[0]?.slug

            dispatch(getChainClinics(chainSlug))
        }

        if (isChainAdminUser) {
            dispatch(loadChains())
        }
    }, [users.userDetails.id])

    if (loading) {
        return <SidebarLayout loading={loading} />
    }

    if (isChainAdmin || isSuperAdmin) {
        const title =
            targetUserRole === UserRolesEnum.REGULAR_USER
                ? translate('users.editRegularUser')
                : translate('users.editChainAdmin')

        return (
            <SidebarLayout loading={loading}>
                <SidebarPageHeader title={title} />

                <EditUserForm
                    {...{
                        store,
                        userId,
                        history,
                        loading,
                        dispatch,
                        isRegularUser,
                        isChainAdminUser,
                        userDetails: users.userDetails,
                    }}
                />

                <DeleteUserBlock
                    {...{ userId, history, dispatch, user: auth.user }}
                />
            </SidebarLayout>
        )
    }

    return (
        <SidebarLayout loading={loading}>
            <Title text={translate('noAccess')} />
        </SidebarLayout>
    )
}

export default withConnectedStore(EditUser)
