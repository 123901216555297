import React from 'react'
import Box from '@material-ui/core/Box'
import { StyledDot } from './styledComponents'

export const WithStatusDot = ({ children, status }) => (
    <Box display="flex" alignItems="center">
        <StyledDot status={status} />
        {children}
    </Box>
)
