import React, { useEffect, useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { translate } from '@utils/translationUtils'
import { TableContainer } from '@shared/components/Table/styledComponents'
import { Row } from '@shared/components/Table/Raw'
import { Title } from '@containers/clinics/components/Title'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Box, AccordionSummary } from '@material-ui/core'
import { Routes } from '@shared/constants/routes'
import { getRouteWithId } from '@utils/routeUtils'
import { useParams } from 'react-router'
import {
    getClinicTreatments,
    updateTreatmentCategoryOrder,
    updateTreatmentCategoryStatus,
} from '@store/reducers/treatments'
import { TreatmentsContent } from './components/TreatmentsContent'
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
} from '@dnd-kit/core'
import {
    SortableContext,
    useSortable,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DotsNine } from '../../../node_modules/phosphor-react/dist/index'

export const Toggle = ({
    isEnabled,
    setIsEnabled,
    id,
    type,
    clinicId,
    dispatch,
    disabled,
}) => {
    const handleToggle = () => {
        if (disabled) return null

        const newStatus = isEnabled ? 0 : 1
        setIsEnabled(!isEnabled)

        const payload = {
            id,
            type,
            active: newStatus,
        }

        dispatch(updateTreatmentCategoryStatus(payload, clinicId))
    }

    return (
        <div
            className={`${
                isEnabled ? 'bg-blue-500' : 'bg-gray-300'
            } relative inline-flex h-6 w-12 rounded-full transition-colors duration-300 ease-in-out shadow-md ${
                disabled ? 'opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleToggle}
        >
            <span
                className={`${
                    isEnabled ? 'translate-x-6' : 'translate-x-0'
                } inline-block h-6 w-6 transform bg-white rounded-full transition-transform duration-300 ease-in-out`}
            />
        </div>
    )
}

const SortableRow = ({
    id,
    name,
    treatments,
    clinicId,
    categoryId,
    dispatch,
    active,
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id })

    const [isEnabled, setIsEnabled] = useState(!id || active === 1)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: '100%',
        marginBottom: '16px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        backgroundColor: isEnabled ? '#fff' : '#e0e0e0',
        opacity: isEnabled ? 1 : 0.5,
    }

    if (!treatments || treatments.length === 0) {
        return null
    }

    return (
        <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Row
                forthCellWidth={12}
                firstCellContent={
                    <div className="flex items-center gap-4 pb-4">
                        <DotsNine size={32} />
                        <Title text={name} />
                    </div>
                }
                forthCellContent={
                    id && (
                        <div className="flex items-center">
                            <span className="text-sm font-medium text-gray-700">
                                {translate(isEnabled ? 'SHOW' : 'HIDE')}
                            </span>
                            <div className="ml-2 flex">
                                <Toggle
                                    isEnabled={isEnabled}
                                    setIsEnabled={setIsEnabled}
                                    id={id}
                                    type="category"
                                    clinicId={clinicId}
                                    dispatch={dispatch}
                                />
                            </div>
                        </div>
                    )
                }
                marginTop={1}
            />
            <TreatmentsContent
                treatments={treatments}
                clinicId={clinicId}
                categoryId={categoryId}
                dispatch={dispatch}
                active={isEnabled ? 1 : 0}
            />
        </Box>
    )
}

const Treatments = ({ store, dispatch }) => {
    const { id } = useParams()
    const [localTreatments, setLocalTreatments] = useState([])
    const { treatments, loading } = store.treatments

    useEffect(() => {
        dispatch(getClinicTreatments(id))
    }, [dispatch, id])

    useEffect(() => {
        if (treatments?.length) {
            const sortedTreatments = treatments.sort(
                (a, b) => a.order - b.order
            )
            setLocalTreatments(sortedTreatments)
        }
    }, [treatments])

    const handleDragEnd = event => {
        const { active, over } = event
        if (!over || active.id === over.id) return

        const oldIndex = localTreatments.findIndex(
            treatment => treatment.id === active.id
        )
        const newIndex = localTreatments.findIndex(
            treatment => treatment.id === over.id
        )

        const newOrder = arrayMove(localTreatments, oldIndex, newIndex)
        setLocalTreatments(newOrder)

        const orders = newOrder.reduce((acc, treatment, index) => {
            acc[treatment.id] = index + 1
            return acc
        }, {})

        const payload = {
            clinic_id: id,
            orders: orders,
        }

        dispatch(updateTreatmentCategoryOrder(payload, id))
    }

    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
    )

    return (
        <SidebarLayout loading={loading}>
            <TableContainer>
                <Box mb={3}>
                    <AccordionSummary expandIcon={null}>
                        <Row
                            firstCellContent={
                                <Title
                                    text={translate('treatment.treatments')}
                                />
                            }
                            thirdCellContent={
                                <UnderlinedLink
                                    text={translate('treatment.editCategories')}
                                    url={getRouteWithId(
                                        Routes.treatmentsCategories,
                                        id
                                    )}
                                    rightAlign="right"
                                />
                            }
                            forthCellContent={
                                <UnderlinedLink
                                    text={translate('addNew')}
                                    url={getRouteWithId(
                                        Routes.treatmentsCreate,
                                        id
                                    )}
                                    rightAlign="right"
                                />
                            }
                            marginTop={1}
                        />
                    </AccordionSummary>

                    {!!localTreatments.length && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={localTreatments.map(t => t.id)}
                                strategy={verticalListSortingStrategy}
                            >
                                {localTreatments.map(treatment => (
                                    <SortableRow
                                        key={treatment.id}
                                        id={treatment.id}
                                        name={treatment.name}
                                        treatments={treatment.treatments}
                                        clinicId={id}
                                        categoryId={treatment.id}
                                        dispatch={dispatch}
                                        active={treatment.active}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    )}
                </Box>
            </TableContainer>
        </SidebarLayout>
    )
}

export default withConnectedStore(Treatments)
