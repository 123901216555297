import { TreatmentFormDataProvider } from 'providers/treatments/TreatmentFormDataProvider'

export class ClinicDataProvider extends TreatmentFormDataProvider {
    treatmentDataProvider = new TreatmentFormDataProvider()

    generateFormData = data => {
        const {
            zip,
            lat,
            file,
            long,
            name,
            type,
            city,
            phone,
            email,
            group,
            country,
            address1,
            address2,
            region_id,
            penalty_fee,
            opus_clinic_id,
            cancellation_time,
        } = data

        const formattedData = {
            zip,
            lat,
            file,
            long,
            name,
            type,
            city,
            phone,
            email,
            group,
            country,
            address1,
            address2,
            region_id,
            opus_clinic_id,
            cancellation_time,
            tips: this.getTipsArray(data),
            penalty_fee: penalty_fee || 0,
        }

        if (!file) {
            delete formattedData.file
        }

        return formattedData
    }
}
