import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import {
    Content,
    DrawerContainer,
    SidebarPageWrapper,
    sidebarStyles,
    SidebarWrapper,
    StyledToolbar,
} from './styledComponents'
import { AppHeader } from '../AppHeader/AppHeader'
import { NavigationConfig } from '@shared/configurations/NavigationConfig'
import { SidebarNavigation } from './SidebarNavigation'
import { useParams } from 'react-router'
import { getTranslatedRole } from '@utils/translationUtils'
import { Link } from 'react-router-dom'
import { Routes } from '@shared/constants/routes'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { ImageSources } from '@shared/constants/imageSources'
import { Loader } from '../Loader/Loader'

const SidebarLayout = ({ loading, children, store }) => {
    const { roles, name } = store.auth.user

    const { drawer, drawerPaper } = sidebarStyles()

    const { id } = useParams()

    const {
        getSidebarTopNavConfig,
        getSidebarClinicNavConfig,
        getSidebarBottomNavConfig,
    } = new NavigationConfig()

    const topNavConfig = id
        ? getSidebarClinicNavConfig(id)
        : getSidebarTopNavConfig(roles[0])

    const userRole = getTranslatedRole(roles[0])

    return (
        <SidebarWrapper>
            <AppHeader />

            <Drawer
                className={drawer}
                variant="permanent"
                anchor="left"
                classes={{
                    paper: drawerPaper,
                }}
            >
                <StyledToolbar>
                    <Link to={Routes.clinics}>
                        <img src={ImageSources.dentalMediaLogo} alt="logo" />
                    </Link>
                </StyledToolbar>

                <DrawerContainer>
                    <SidebarNavigation config={topNavConfig} />

                    <SidebarNavigation
                        config={getSidebarBottomNavConfig(name, userRole)}
                    />
                </DrawerContainer>
            </Drawer>
            <Content>
                <Toolbar />
                <SidebarPageWrapper>
                    <Loader loading={loading}>{children}</Loader>
                </SidebarPageWrapper>
            </Content>
        </SidebarWrapper>
    )
}

const withStore = withConnectedStore(SidebarLayout)

export { withStore as SidebarLayout }
