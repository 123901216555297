import styled from '@emotion/styled'
import { Colors } from '@shared/constants/colors'

const InputError = styled.div`
    font-size: 14px;
    color: red;
`

const InputMessage = styled.div`
    font-size: 14px;
    color: ${Colors.gray[200]};
`

const InputWrapper = styled.div`
    position: relative;
`

const InputLabel = styled.span`
    display: block;
    margin-bottom: 5px;
`

const Input = styled.input`
    padding: 10px;
    width: 100%;
    border: 1px solid ${Colors.gray[300]};
    border-radius: 5px;
    outline: none;
`

const TextArea = styled.textarea`
    font-family: Arial, Helvetica, sans-serif;
    min-height: 90px;
`

export { Input, TextArea, InputError, InputWrapper, InputLabel, InputMessage }
