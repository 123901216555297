import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { createChainAdmin, createRegularUser } from '@store/reducers/users'
import { loadChains } from '@store/reducers/chains'
import { Form } from '@shared/components/Form/Form'
import { useForm } from 'react-hook-form'
import { getCreateUserFormConfig } from './configurations/CreateUserFormConfig'
import { UserRolesEnum } from '@enums/UserRolesEnum'
import { getChainClinics } from '@store/reducers/clinics'
import { CreateUserValidator } from './configurations/CreateUserFormValidators'
import { yupResolver } from '@hookform/resolvers/yup'
import { Routes } from '@shared/constants/routes'
import { useHistory } from 'react-router'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { Title } from '@containers/clinics/components/Title'

const CreateUser = ({ dispatch, store }) => {
    const history = useHistory()
    const { auth, chains, users, clinics } = store

    const loading = chains.loading || users.loading
    const userRole = auth.user.roles[0]

    const isChainAdmin = userRole === UserRolesEnum.CHAIN_ADMIN
    const isSuperAdmin = userRole === UserRolesEnum.SUPER_ADMIN

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CreateUserValidator),
    })

    useEffect(() => {
        if (isChainAdmin) {
            const chainSlug = auth.user.chains[0]?.slug

            dispatch(getChainClinics(chainSlug))
        } else {
            dispatch(loadChains())
        }
    }, [])

    const onSubmit = async data => {
        try {
            if (isChainAdmin) {
                await dispatch(createRegularUser(data))
                showSuccessMessage('notification.regularUserCreated')
            } else {
                await dispatch(createChainAdmin(data))
                showSuccessMessage('notification.chainAdminCreated')
            }

            history.push(Routes.users)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const clinicsOptions = clinics.chainClinics.map(({ id, name }) => ({
        value: id,
        label: name,
    }))

    const chainsOptions = [
        { id: null, name: translate('users.noChain') },
        ...chains.chains,
    ].map(({ id, name }) => ({
        value: id,
        label: name,
    }))

    const formConfig = getCreateUserFormConfig(
        isChainAdmin,
        isChainAdmin ? clinicsOptions : chainsOptions
    )

    if (isChainAdmin || isSuperAdmin) {
        return (
            <SidebarLayout loading={loading}>
                <SidebarPageHeader title={translate('users.addUser')} />

                <Form
                    {...{
                        errors,
                        control,
                        formConfig,
                        isDisabled: false,
                        fixedBtnWidth: true,
                        buttonText: translate('save'),
                        onConfirm: handleSubmit(onSubmit),
                    }}
                />
            </SidebarLayout>
        )
    }

    return (
        <SidebarLayout loading={loading}>
            <Title text={translate('noAccess')} />
        </SidebarLayout>
    )
}

export default withConnectedStore(CreateUser)
