import CreateUser from '@containers/createUser/index'
import EditUser from '@containers/editUser/index'
import Users from '@containers/users/index'
import { Routes } from '@shared/constants/routes'

export const userRoutes = [
    {
        routeParams: {
            path: Routes.users,
            exact: true,
        },
        component: Users,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.addUser,
        },
        component: CreateUser,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.editUser,
        },
        component: EditUser,
        isPrivate: true,
    },
]
