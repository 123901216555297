import React, { useEffect, useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { Form } from '@shared/components/Form/Form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ProfileFormConfig } from './configurations/ProfileFormConfig'
import {
    ProfileValidator,
    ProfileValidatorWithPasswords,
} from './configurations/ProfileValidator'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { updateProfile } from '@store/reducers/authorization'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'

const Profile = ({ store, dispatch }) => {
    const { user, loading } = store.auth
    const [formValidator, setFormValidator] = useState(ProfileValidator)

    const {
        reset,
        watch,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formValidator),
    })

    const passwordValue = watch('password')

    const onSubmit = async data => {
        try {
            await dispatch(updateProfile(data))
            showSuccessMessage('notification.profileUpdated')
        } catch (error) {
            showErrorMessage(error)
        }
    }

    useEffect(() => {
        if (passwordValue) {
            setFormValidator(ProfileValidatorWithPasswords(user.email))
        } else {
            setFormValidator(ProfileValidator)
        }
    }, [passwordValue])

    useEffect(() => {
        reset({
            name: user.name,
            email: user.email,
            password: '',
            password_confirmation: '',
        })
    }, [])

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('profile.yourProfile')} />

            <Form
                {...{
                    errors,
                    control,
                    formConfig: ProfileFormConfig,
                    buttonMargin: 5,
                    isDisabled: false,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                }}
            />
        </SidebarLayout>
    )
}

export default withConnectedStore(Profile)
