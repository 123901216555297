import { Routes } from '@shared/constants/routes'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component, ...rest }) => {
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized)

    const Component = component

    return (
        <Route
            {...rest}
            render={() =>
                isAuthorized ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.login,
                        }}
                    />
                )
            }
        />
    )
}
