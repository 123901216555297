import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { CreateTreatmentForm } from './components/CreateTreatmentForm'
import { withConnectedStore } from '@hocs/withConnectedStore'

const CreateTreatment = ({ store }) => {
    const loading = store.treatments.loading

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('treatment.addNew')} />

            <CreateTreatmentForm />
        </SidebarLayout>
    )
}

export default withConnectedStore(CreateTreatment)
