import React, { useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/Form/Form'
import { CreateChainFormConfig } from './configurations/CreateChainFormConfig'
import { CreateChainValidator } from './configurations/ChainFormValidators'
import { ImagePicker } from '@shared/components/ImagePicker/ImagePicker'
import { createChain } from '@store/reducers/chains'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { getSlugString } from '@utils/stringUtils'
import { Routes } from '@shared/constants/routes'
import { useHistory } from 'react-router'

const CreateChain = ({ dispatch, store }) => {
    const history = useHistory()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CreateChainValidator),
    })

    const { loading } = store.chains

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isSizeError, setSizeError] = useState(false)

    const onSubmit = async data => {
        try {
            const slug = getSlugString(data.name)
            data.slug = slug

            if (selectedFile) {
                data.file = selectedFile
            }

            await dispatch(createChain(data))
            showSuccessMessage('notification.chainCreated')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('chain.createNew')} />

            <Form
                {...{
                    errors,
                    control,
                    isDisabled: isSizeError,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                    formConfig: CreateChainFormConfig,
                }}
            >
                <ImagePicker
                    {...{
                        label: 'form.logo',
                        isSizeError,
                        setSizeError,
                        selectedImage,
                        setSelectedFile,
                        setSelectedImage,
                    }}
                />
            </Form>
        </SidebarLayout>
    )
}

export default withConnectedStore(CreateChain)
