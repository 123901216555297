import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { StyledInput } from '@shared/components/StyledInput/StyledInput'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'
import { FormContainer } from '@containers/createChain/styledComponents'

export const Form = ({
    children = null,
    buttonMargin = 2,
    fullWidthForm = false,
    errors,
    control,
    register,
    onConfirm,
    isDisabled,
    buttonText,
    formConfig,
    fixedBtnWidth,
}) => (
    <FormContainer fullwidth={fullWidthForm}>
        <form>
            <Grid container spacing={1}>
                {formConfig.map((config, index) => (
                    <Grid item xs={config.smallRow ? 6 : 12} key={config.id}>
                        <Box
                            width={
                                config.smallRow || fullWidthForm
                                    ? '100%'
                                    : '50%'
                            }
                            pl={config.smallRow && index % 2 && 2}
                        >
                            <StyledInput
                                {...config}
                                {...{
                                    errors,
                                    control,
                                    register,
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box width="50%">{children}</Box>

            <Box mt={buttonMargin} width={fixedBtnWidth ? '150px' : '100%'}>
                <PrimaryButton
                    text={buttonText}
                    onClick={onConfirm}
                    disabled={isDisabled}
                />
            </Box>
        </form>
    </FormContainer>
)
