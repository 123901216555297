import React, { useEffect, useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { translate } from '@utils/translationUtils'
import {
    ClinicTipsFormConfig,
    getClinicFormConfig,
} from './configurations/CreateClinicFormConfig'
import { ImagePicker } from '@shared/components/ImagePicker/ImagePicker'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { Form } from '@shared/components/Form/Form'
import { createClinic } from '@store/reducers/clinics'
import { useHistory, useParams } from 'react-router'
import { Routes } from '@shared/constants/routes'
import { getChainRegions } from '@store/reducers/regions'
import { Box } from '@material-ui/core'
import { CreateTreatmentFromSection } from '@containers/createTreatment/components/CreateTreatmentFromSection'
import { withClinicTips } from '@hocs/withClinicTips'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'
import { ClinicDataProvider } from 'providers/clinic/ClinicDataProvider'

const CreateClinic = ({
    store,
    errors,
    control,
    dispatch,
    register,
    removeTip,
    addNewTip,
    clinicTips,
    handleSubmit,
    needShowTips,
    maxTipsLength,
}) => {
    const clinicDataProvider = new ClinicDataProvider()

    const history = useHistory()
    const { slug } = useParams()

    const { loading, regions } = store.regions

    useEffect(() => {
        dispatch(getChainRegions(slug))
    }, [])

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isSizeError, setSizeError] = useState(false)

    const onSubmit = async data => {
        try {
            if (selectedFile) {
                data.file = selectedFile
            }

            const formData = clinicDataProvider.generateFormData(data)

            await dispatch(createClinic(formData, slug))

            showSuccessMessage('notification.clinicCreated')
            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const formConfig = getClinicFormConfig(regions)

    if (loading) {
        return <SidebarLayout loading={loading} />
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('clinic.createNew')} />

            <Form
                {...{
                    errors,
                    control,
                    register,
                    formConfig,
                    isDisabled: isSizeError,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                }}
            >
                <Box>
                    <CreateTreatmentFromSection
                        {...{ errors, control, register }}
                        config={ClinicTipsFormConfig.showTips}
                    />

                    {needShowTips && (
                        <CreateTreatmentFromSection
                            {...{ errors, control, register }}
                            config={clinicTips}
                            removeTip={removeTip}
                            isClinicTip
                        />
                    )}

                    {needShowTips && clinicTips.length !== maxTipsLength && (
                        <Box mt={2} width="150px">
                            <PrimaryButton
                                text={translate('treatment.addTip')}
                                onClick={addNewTip}
                                disabled={false}
                            />
                        </Box>
                    )}
                </Box>

                <ImagePicker
                    {...{
                        label: 'form.logo',
                        isSizeError,
                        setSizeError,
                        selectedImage,
                        setSelectedFile,
                        setSelectedImage,
                    }}
                />
            </Form>
        </SidebarLayout>
    )
}

export default withConnectedStore(withClinicTips(CreateClinic))
