import styled from '@emotion/styled'
import { UserStatusesEnum } from '@enums/UserStatutesEnum'

export const StyledDot = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${({ status }) =>
        status === UserStatusesEnum.ACTIVATED ? '#01FF34' : '#FF0000'};
    border-radius: 50%;
    margin-right: 8px;
`
