import { string, object } from 'yup'

export const PractitionerFormValidators = object().shape({
    name: string().required('Name is required').min(1).max(50),
    title: string().required('Title is required'),
    education: string().max(150),
    biography: string().max(255),
    opus_clinician_id: string().required(
        'Opus Dental Clinician Id is required'
    ),
    status: object()
        .shape({
            label: string().required('Status is required'),
            value: string().required('Status is required'),
        })
        .typeError('Status is required'),
})
