import React from 'react'
import { translate } from '@utils/translationUtils'
import { Box, Grid } from '@material-ui/core'
import { Form } from '@shared/components/Form/Form'
import { PractitionerFormConfig } from '../configurations/PractitionerFormConfig'
import { ImagePicker } from '@shared/components/ImagePicker/ImagePicker'

export const PractitionerForm = ({
    errors,
    control,
    onSubmit,
    isSizeError,
    handleSubmit,
    setSizeError,
    selectedImage,
    setSelectedFile,
    setSelectedImage,
}) => {
    return (
        <Grid container spacing={1}>
            <Box display="inline-block" width="45%" mr={5}>
                <Form
                    {...{
                        errors,
                        control,
                        fullWidthForm: true,
                        formConfig: PractitionerFormConfig,
                        isDisabled: isSizeError,
                        fixedBtnWidth: true,
                        buttonMargin: 7,
                        buttonText: translate('save'),
                        onConfirm: handleSubmit(onSubmit),
                    }}
                />
            </Box>

            <Box
                marginTop={-3}
                display="inline-block"
                width="300px"
                marginLeft="auto"
            >
                <ImagePicker
                    {...{
                        label: 'photo',
                        imagePickerHeight: 300,
                        isSizeError,
                        setSizeError,
                        selectedImage,
                        setSelectedFile,
                        setSelectedImage,
                    }}
                />
            </Box>
        </Grid>
    )
}
