import { string, object } from 'yup'

export const CreateClinicValidator = {
    name: string().required('Clinic name is required').min(1).max(50),
    email: string().email('Email is not valid').required('Email is required'),
    cancellation_time: object()
        .shape({
            label: string().required('Cancellation Policy is required'),
            value: string().required('Cancellation Policy is required'),
        })
        .typeError('Cancellation Policy is required'),
    type: object()
        .shape({
            label: string().required('Clinic Type is required'),
            value: string().required('Clinic Type is required'),
        })
        .typeError('Clinic Type is required'),
    // opusDentalKey: string().required('Opus Dental Key is required'),
    // googlePlaceID: string().required('Google Place ID is required'),
    country: string().required('Country is required'),
    city: string().required('City is required'),
    zip: string().max(15),
    address1: string().required('Field is required'),
    phone: string().required('Phone is required'),
    lat: string().required('Latitude is required'),
    long: string().required('Longitude is required'),
}
