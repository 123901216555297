import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/Form/Form'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import { Routes } from '@shared/constants/routes'
import Box from '@material-ui/core/Box'
import { CategoryFormValidator } from '@containers/createCategory/configurations/CategoryFormValidators'
import { CategoryFormConfig } from '@containers/createCategory/configurations/CategoryFormConfig'
import { getRouteWithId } from '@utils/routeUtils'
import {
    deleteCategory,
    getCategoryDetails,
    updateCategory,
} from '@store/reducers/treatments'

const EditCategory = ({ store, dispatch }) => {
    const { categoryDetails, loading } = store.treatments

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CategoryFormValidator),
    })

    const history = useHistory()
    const { id, categoryId } = useParams()

    useEffect(() => {
        dispatch(getCategoryDetails(categoryId))
    }, [])

    useEffect(() => {
        reset({ name: categoryDetails.name })
    }, [categoryDetails.name])

    const onSubmit = async data => {
        try {
            await dispatch(updateCategory(data, categoryDetails.id))
            showSuccessMessage('notification.categoryUpdated')

            history.push(getRouteWithId(Routes.treatmentsCategories, id))
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const onCategoryDelete = async () => {
        try {
            await dispatch(deleteCategory(categoryDetails.id))
            showSuccessMessage('notification.categoryDeleted')

            history.push(getRouteWithId(Routes.treatmentsCategories, id))
        } catch (error) {
            showErrorMessage(error)
        }
    }

    if (!categoryDetails.name) {
        return <SidebarLayout loading={loading} />
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('treatment.editCategory')} />

            <Form
                {...{
                    errors,
                    control,
                    formConfig: CategoryFormConfig,
                    isDisabled: false,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                }}
            />

            <Box marginTop={2}>
                <UnderlinedButton
                    onClick={onCategoryDelete}
                    translationKey={'treatment.deleteCategory'}
                />
            </Box>
        </SidebarLayout>
    )
}

export default withConnectedStore(EditCategory)
