import { string } from 'yup'

export const checkDigitsOnly = value => /^\d+$/.test(value)

export const CreateTreatmentValidator = {
    price: string()
        .required('Price is required')
        .test(
            'Digits only',
            'The field should have digits only',
            checkDigitsOnly
        ),
    title: string().min(1).max(50),
    duration: string()
        .required('Duration is required')
        .matches(/^[0-9]+$/, 'Please enter only numbers')
        .test(
            'Digits only',
            'The field should have digits only',
            checkDigitsOnly
        ),
    long_description: string().required('Long description is required'),
    short_description: string().required('Short description is required'),
}
