import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import { appHeaderStyles } from './styledComponents'
import { translate } from '@utils/translationUtils'

export const AppHeader = () => {
    const { appBar } = appHeaderStyles()

    return (
        <AppBar position="fixed" className={appBar}>
            <Toolbar>
                <Typography variant="h5" noWrap>
                    {translate('appBar.onlineBooking')}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}
