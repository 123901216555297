import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/Form/Form'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { Routes } from '@shared/constants/routes'
import { useHistory, useParams } from 'react-router'
import {
    editRegion,
    deleteRegion,
    getRegionDetails,
} from '@store/reducers/regions'
import { CreateRegionValidator } from '@containers/createRegion/configurations/RegionFormValidators'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import Box from '@material-ui/core/Box'
import { CreateRegionFormConfig } from '@containers/createRegion/configurations/CreateRegionFormConfig'

const EditRegion = ({ dispatch, store }) => {
    const history = useHistory()
    const { regionId } = useParams()

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CreateRegionValidator),
    })

    const {
        loading,
        regionDetails: { name },
    } = store.regions

    useEffect(() => {
        dispatch(getRegionDetails(regionId))
    }, [])

    useEffect(() => {
        reset({ name })
    }, [name])

    const onSubmit = async data => {
        try {
            await dispatch(editRegion(data, regionId))
            showSuccessMessage('notification.regionUpdated')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const onRegionDelete = async () => {
        try {
            await dispatch(deleteRegion(regionId))
            showSuccessMessage('notification.regionDeleted')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    if (!name) {
        return <SidebarLayout loading={loading} />
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={name} />

            <Form
                {...{
                    errors,
                    control,
                    formConfig: CreateRegionFormConfig,
                    isDisabled: false,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                }}
            />

            <Box marginTop={2}>
                <UnderlinedButton
                    onClick={onRegionDelete}
                    translationKey={'region.delete'}
                />
            </Box>
        </SidebarLayout>
    )
}

export default withConnectedStore(EditRegion)
