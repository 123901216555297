import React, { useEffect, useState } from 'react'
import { getCreateTreatmentFormConfig } from '@containers/createTreatment/configurations/CreateTreatmentFormConfig'
import { CreateTreatmentValidator } from '@containers/createTreatment/configurations/CreateTreatmentFormValidators'
import { translate } from '@utils/translationUtils'
import { TreatmentFormDataProvider } from 'providers/treatments/TreatmentFormDataProvider'
import { useHistory, useParams } from 'react-router'
import { TrueFalseEnum } from '@enums/TrueFalseEnum'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { getRouteWithId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { getKey } from '@utils/arrayUtils'
import { getCategories } from '@store/reducers/treatments'

export const withTreatmentTips = WrappedComponent => {
    const Component = props => {
        const { id, treatmentId } = useParams()
        const categories = props.store.treatments.categories
        const history = useHistory()

        const maxTipsLength = 20

        const treatmentFormDataProvider = new TreatmentFormDataProvider()
        const CreateTreatmentFormConfig = getCreateTreatmentFormConfig()

        const [treatmentTips, setTreatmentTips] = useState(
            treatmentId ? [] : CreateTreatmentFormConfig.tips
        )

        const [formValidator, setFormValidator] = useState(
            CreateTreatmentValidator
        )

        const {
            reset,
            watch,
            control,
            register,
            unregister,
            handleSubmit,
            formState: { errors },
        } = useForm({
            resolver: yupResolver(object().shape(formValidator)),
        })

        const redirectOnTreatments = () =>
            history.push(getRouteWithId(Routes.treatments, id))

        const addNewTip = () => {
            const firstTip = treatmentTips[0]
            const secondTip = treatmentTips[1]
            const beforeLastTip = { ...treatmentTips[treatmentTips.length - 2] }
            const lastTip = { ...treatmentTips[treatmentTips.length - 1] }

            const nextTipNumber = lastTip.tipNumber + 1

            setTreatmentTips([
                ...treatmentTips,
                {
                    ...beforeLastTip,
                    isTip: true,
                    id: getKey(),
                    tipNumber: nextTipNumber,
                    label: translate(`treatment.tipHeader${nextTipNumber}`),
                    fieldProps: {
                        ...beforeLastTip.fieldProps,
                        name: `${firstTip.fieldProps.name}${nextTipNumber}`,
                    },
                },
                {
                    ...lastTip,
                    isTip: true,
                    id: getKey(),
                    tipNumber: nextTipNumber,
                    label: translate(`treatment.tipText${nextTipNumber}`),
                    fieldProps: {
                        ...lastTip.fieldProps,
                        name: `${secondTip.fieldProps.name}${nextTipNumber}`,
                    },
                },
            ])
        }

        const removeTip = tipNum => {
            const filteredTips = treatmentTips.filter(
                ({ tipNumber }) => tipNum !== tipNumber
            )
            const fieldNamesForUnregister = treatmentTips
                .filter(({ tipNumber }) => tipNum === tipNumber)
                .map(({ fieldProps }) => fieldProps.name)

            setTreatmentTips(filteredTips)
            unregister(fieldNamesForUnregister)

            const updatedValidators = treatmentFormDataProvider.removeTipsFromValidatorsByNames(
                formValidator,
                fieldNamesForUnregister
            )
            setFormValidator(updatedValidators)
        }

        const showTipsSelectedOption = watch(
            CreateTreatmentFormConfig.showTips[0].fieldProps.name
        )

        const needShowTips =
            showTipsSelectedOption &&
            showTipsSelectedOption.value === TrueFalseEnum.YES

        useEffect(() => {
            if (needShowTips) {
                const updatedValidators = treatmentFormDataProvider.addTipsToValidators(
                    formValidator,
                    treatmentTips
                )
                setFormValidator(updatedValidators)
            } else {
                const updatedValidators = treatmentFormDataProvider.removeTipsFromValidators(
                    formValidator,
                    treatmentTips
                )
                setFormValidator(updatedValidators)

                const fieldNamesForUnregister = treatmentTips.map(
                    ({ fieldProps }) => fieldProps.name
                )
                unregister(fieldNamesForUnregister)
            }
        }, [needShowTips, treatmentTips.length])

        useEffect(() => {
            props.dispatch(getCategories(id))
        }, [])

        return (
            <WrappedComponent
                {...props}
                {...{
                    id,
                    reset,
                    watch,
                    errors,
                    control,
                    register,
                    removeTip,
                    addNewTip,
                    categories,
                    treatmentId,
                    handleSubmit,
                    needShowTips,
                    formValidator,
                    treatmentTips,
                    maxTipsLength,
                    setTreatmentTips,
                    redirectOnTreatments,
                }}
            />
        )
    }

    return Component
}
