import React from 'react'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Row } from '@shared/components/Table/Raw'
import { Title } from '@containers/clinics/components/Title'
import { getRouteWithUserId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { translate } from '@utils/translationUtils'
import { WithStatusDot } from '@shared/components/StatusDot/WithStatusDot'
import { UserRolesEnum } from '@enums/UserRolesEnum'

export const UsersContent = ({
    id,
    name,
    image,
    email,
    roles,
    status,
    clinics = [],
    chains = [],
    withoutEditing,
}) => {
    const chainsNames = chains
        .map(({ name }) => name)
        .toString()
        .replace(',', ', ')

    const isRegularUser = UserRolesEnum.REGULAR_USER === roles[0]

    const regularUserComponent = (
        <span>
            {clinics.length}{' '}
            {translate(clinics.length === 1 ? 'oneClinic' : 'clinics')}
        </span>
    )

    const thirdCellText = isRegularUser ? regularUserComponent : chainsNames

    return (
        <Row
            firstCellContent={
                <WithStatusDot status={status}>
                    <Title text={name} imageSrc={image} isSemiBold />
                </WithStatusDot>
            }
            secondCellContent={email}
            thirdCellContent={thirdCellText}
            forthCellContent={
                !withoutEditing && (
                    <UnderlinedLink
                        text={translate('edit')}
                        url={getRouteWithUserId(Routes.editUser, id)}
                        rightAlign="right"
                    />
                )
            }
            marginTop={1}
        />
    )
}
