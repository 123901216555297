import { UserRolesEnum } from '@enums/UserRolesEnum'

export const getSortedUsersByRoles = usersArray => {
    const users = {
        superUsers: [],
        regularUsers: [],
        chainAdministrators: [],
    }

    usersArray.forEach(user => {
        const userRole = user.roles[0]

        if (userRole === UserRolesEnum.SUPER_ADMIN) {
            users.superUsers.push(user)
        }
        if (userRole === UserRolesEnum.CHAIN_ADMIN) {
            users.chainAdministrators.push(user)
        }
        if (userRole === UserRolesEnum.REGULAR_USER) {
            users.regularUsers.push(user)
        }
    })

    return users
}

export const checkAccess = (permissions = [], requiredPermission) =>
    !!permissions.find(permission => permission === requiredPermission)
