import { envConfig } from '@infrastructure/envConfig'

export class UsersUrlProvider {
    baseUrl = `${envConfig.API_URL}`

    usersUrl = () => `${this.baseUrl}/users`

    adminUrl = () => `${this.usersUrl()}/admin`

    userByIdUrl = id => `${this.usersUrl()}/${id}`

    adminByIdUrl = id => `${this.adminUrl()}/${id}`

    regularUserUrl = () => `${this.usersUrl()}/clinic-user`

    regularUserByIdUrl = id => `${this.regularUserUrl()}/${id}`
}
