import React from 'react'
import {
    LoginContainer,
    LoginFormWrapper,
    LoginToolbar,
} from '@containers/login/styledComponents'
import { AppHeader } from '@shared/components/AppHeader/AppHeader'
import { Loader } from '@shared/components/Loader/Loader'
import { FormTitle } from '@containers/login/components/FormTitle'
import { useSelector } from 'react-redux'
import { ImageSources } from '@shared/constants/imageSources'

export const AuthLayout = ({ children, formTitle }) => {
    const loading = useSelector(({ auth }) => auth.loading)

    return (
        <LoginContainer>
            <LoginFormWrapper>
                <Loader loading={loading}>
                    <LoginToolbar>
                        <img src={ImageSources.dentalMediaLogo} alt="logo" />
                    </LoginToolbar>

                    <AppHeader />

                    <FormTitle title={formTitle} />

                    {children}
                </Loader>
            </LoginFormWrapper>
        </LoginContainer>
    )
}
