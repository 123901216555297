import { authRoutes } from './routes/authRoutes'
import { chainRoutes } from './routes/chainRoutes'
import { userRoutes } from './routes/userRoutes'
import { otherRoutes } from './routes/otherRoutes'
import { regionRoutes } from './routes/regionRoutes'
import { clinicRoutes } from './routes/clinicRoutes'
import { treatmentRoutes } from './routes/treatmentRoutes'
import { practitionerRoutes } from './routes/practitionerRoutes'

export const RoutesConfig = [
    ...authRoutes,
    ...userRoutes,
    ...chainRoutes,
    ...otherRoutes,
    ...clinicRoutes,
    ...regionRoutes,
    ...treatmentRoutes,
    ...practitionerRoutes,
]
