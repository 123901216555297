import styled from '@emotion/styled'
import CloseIcon from '@material-ui/icons/Close'

export const StyledCloseIcon = styled(CloseIcon)`
    color: red;
    position: absolute;
    right: -30px;
    cursor: pointer;
    top: 35px;
`
