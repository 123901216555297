import { TimezonesEnum } from '@enums/TimezonesEnum'
import { TrueFalseEnum } from '@enums/TrueFalseEnum'
import { generate } from 'shortid'
import moment from 'moment-timezone'

export const getKey = generate

export const findSelectOptionByValue = (options, selectedValue) =>
    options.find(({ value }) => selectedValue === value)

export const getTrueFalseOptions = () => [
    { value: TrueFalseEnum.YES, label: 'Yes' },
    { value: TrueFalseEnum.NO, label: 'No' },
]

export const getTimezonesArray = () =>
    Object.values(TimezonesEnum).map(
        timezone => moment.tz.zonesForCountry(timezone)[0]
    )
