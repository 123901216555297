import Axios from 'axios'
import typeToReducer from 'type-to-reducer'
import { createAction } from '@reduxjs/toolkit'
import { UsersUrlProvider } from '@store/apiUrlProviders/UsersUrlProvider'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { baseActions } from '@store/baseActions'
import { getSortedUsersByRoles } from '@utils/userUtils'
import { getFormData } from '@utils/requestUtils'
import { baseReducer } from '@store/baseReducers'

const usersUrlProvider = new UsersUrlProvider()
const authorizationProvider = new AuthorizationProvider()

const initialState = {
    loading: false,
    users: {},
    userDetails: {
        chains: [],
        roles: [],
    },
}

// ACTIONS
export const getUsers = createAction('GET_USERS', () => ({
    payload: Axios.get(
        usersUrlProvider.usersUrl(),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const createChainAdmin = createAction('CREATE_CHAIN_ADMIN', data => {
    if (!data.chain || !data.chain?.value) {
        delete data.chain
    }

    const formData = getFormData(data)

    return {
        payload: Axios.post(
            usersUrlProvider.adminUrl(),
            formData,
            authorizationProvider.getAuthHeaders()
        ),
    }
})

export const updateChainAdmin = createAction(
    'UPDATE_CHAIN_ADMIN',
    (data, id) => {
        if (!data.chain || !data.chain?.value) {
            delete data.chain
        }

        const formData = getFormData(data)

        return {
            payload: Axios.post(
                usersUrlProvider.adminByIdUrl(id),
                formData,
                authorizationProvider.getAuthHeaders()
            ),
        }
    }
)

export const getUserDetails = createAction('GET_USER_DETAILS', id => ({
    payload: Axios.get(
        usersUrlProvider.userByIdUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const deleteUser = createAction('DELETE_USER', id => ({
    payload: Axios.delete(
        usersUrlProvider.userByIdUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const createRegularUser = createAction('CREATE_REGULAR_USER', data => {
    return manageRegularUser(data, usersUrlProvider.regularUserUrl())
})

export const updateRegularUser = createAction(
    'UPDATE_REGULAR_USER',
    (data, id) => {
        return manageRegularUser(data, usersUrlProvider.regularUserByIdUrl(id))
    }
)

const manageRegularUser = (data, url) => {
    const formData = getFormData(data)

    if (data.clinics.length) {
        const clinics = data.clinics

        formData.delete('clinics')

        clinics.forEach(({ value }) => {
            formData.append('clinics[]', value)
        })
    } else {
        formData.delete('clinics')
    }

    return {
        payload: Axios.post(
            url,
            formData,
            authorizationProvider.getAuthHeaders({ urlencoded: true })
        ),
    }
}

// REDUCERS
const reducer = {
    [getUsers]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            users: getSortedUsersByRoles(payload.data),
            userDetails: initialState.userDetails,
            loading: false,
        }),
    },
    [getUserDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            userDetails: { ...payload.data, chains: payload.data.chains || [] },
            loading: false,
        }),
    },
    [createRegularUser]: {
        ...baseActions,
        FULFILLED: state => ({
            ...state,
            loading: false,
        }),
    },
    [updateRegularUser]: baseReducer,
    [updateChainAdmin]: baseReducer,
    [createChainAdmin]: baseReducer,
    [deleteUser]: baseReducer,
}

export default typeToReducer(reducer, initialState)
