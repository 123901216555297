import ForgotPassword from '@containers/forgotPassword/index'
import Login from '@containers/login/index'
import ResetPassword from '@containers/resetPassword/index'
import { Routes } from '@shared/constants/routes'

export const authRoutes = [
    {
        routeParams: {
            path: Routes.login,
        },
        component: Login,
        isPrivate: false,
    },
    {
        routeParams: {
            path: Routes.forgotPassword,
        },
        component: ForgotPassword,
        isPrivate: false,
    },
    {
        routeParams: {
            path: Routes.resetPassword,
        },
        component: ResetPassword,
        isPrivate: false,
    },
]
