import React, { useEffect } from 'react'
import { Form } from '@shared/components/Form/Form'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateChainAdmin, updateRegularUser } from '@store/reducers/users'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { findSelectOptionByValue } from '@utils/arrayUtils'
import { EditUserValidator } from '@containers/createUser/configurations/CreateUserFormValidators'
import {
    getCreateUserFormConfig,
    statusOptions,
} from '@containers/createUser/configurations/CreateUserFormConfig'
import { Routes } from '@shared/constants/routes'

export const EditUserForm = ({
    store,
    userId,
    history,
    loading,
    dispatch,
    userDetails,
    isRegularUser,
    isChainAdminUser,
}) => {
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EditUserValidator),
    })

    const { chains, clinics } = store

    const {
        name,
        email,
        status,
        chains: [chain],
    } = userDetails

    const clinicsOptions = clinics.chainClinics.map(({ id, name }) => ({
        value: id,
        label: name,
    }))

    const chainsOptions = [
        { id: null, name: translate('users.noChain') },
        ...chains.chains,
    ].map(({ id, name }) => ({
        value: id,
        label: name,
    }))

    const defaultValues = {
        name,
        email,
        status: findSelectOptionByValue(statusOptions, status),
    }

    if (isChainAdminUser) {
        defaultValues.chain = findSelectOptionByValue(chainsOptions, chain?.id)
    }

    if (isRegularUser) {
        defaultValues.clinics = userDetails.clinics?.map(({ id, name }) => ({
            value: id,
            label: name,
        }))
    }

    useEffect(() => {
        reset(defaultValues)
    }, [name, loading])

    const onSubmit = async data => {
        try {
            delete data.email
            data._method = 'PUT'

            if (isRegularUser) {
                await dispatch(updateRegularUser(data, userId))
                showSuccessMessage('notification.regularUserUpdated')
            } else {
                await dispatch(updateChainAdmin(data, userId))
                showSuccessMessage('notification.chainAdminUpdated')
            }

            history.push(Routes.users)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const formConfig = getCreateUserFormConfig(
        isRegularUser,
        isRegularUser ? clinicsOptions : chainsOptions,
        true
    )

    return (
        <Form
            {...{
                errors,
                control,
                formConfig,
                isDisabled: false,
                fixedBtnWidth: true,
                buttonText: translate('save'),
                onConfirm: handleSubmit(onSubmit),
            }}
        />
    )
}
