import { ClinicTypeEnum } from '@enums/ClinicTypeEnum'
import { getKey, getTrueFalseOptions } from '@utils/arrayUtils'
import { translate } from '@utils/translationUtils'
import Select from 'react-select'

const trueFalseOptions = getTrueFalseOptions()

export const hoursOptions = [
    { value: 6, label: '6 hours' },
    { value: 12, label: '12 hours' },
    { value: 24, label: '24 hours' },
    { value: 48, label: '48 hours' },
    { value: 72, label: '72 hours' },
]

export const clinicTypeOptions = [
    { value: ClinicTypeEnum.INTEGRATED, label: 'Integrated' },
    { value: ClinicTypeEnum.NOT_INTEGRATED, label: 'Not Integrated' },
]

export const noRegionOption = { value: '', label: translate('clinic.noRegion') }

export const getClinicFormConfig = regions => {
    const regionsOptions = regions.map(({ id, name }) => ({
        value: id,
        label: name,
    }))

    regionsOptions.unshift(noRegionOption)

    return [
        {
            id: 0,
            label: translate('clinic.clinicName'),
            smallRow: true,
            fieldProps: {
                name: 'name',
                placeholder: 'clinic.enterClinicName',
                required: true,
            },
        },
        {
            id: 1,
            label: translate('clinic.cancellationPolicy'),
            smallRow: true,
            element: Select,
            fieldProps: {
                name: 'cancellation_time',
                placeholder: 'clinic.enterCancellationPolicy',
                options: hoursOptions,
                className: 'booking-select',
                classNamePrefix: 'custom-select',
                required: true,
                isSearchable: false,
            },
        },
        {
            id: 2,
            label: translate('clinic.clinicType'),
            smallRow: true,
            element: Select,
            fieldProps: {
                name: 'type',
                placeholder: 'clinic.selectClinicType',
                required: true,
                className: 'booking-select',
                classNamePrefix: 'custom-select',
                options: clinicTypeOptions,
                isSearchable: false,
            },
        },
        {
            id: 3,
            label: translate('clinic.region'),
            smallRow: true,
            element: Select,
            fieldProps: {
                defaultValue: regionsOptions[0],
                name: 'region_id',
                placeholder: 'clinic.selectRegion',
                required: true,
                className: 'booking-select',
                classNamePrefix: 'custom-select',
                options: regionsOptions,
                isSearchable: false,
            },
        },
        {
            id: 7,
            label: translate('clinic.country'),
            smallRow: true,
            fieldProps: {
                name: 'country',
                placeholder: 'clinic.enterCountry',
                required: true,
            },
        },
        {
            id: 8,
            label: translate('clinic.city'),
            smallRow: true,
            fieldProps: {
                name: 'city',
                placeholder: 'clinic.enterCity',
                required: true,
            },
        },
        {
            id: 9,
            label: translate('clinic.address1'),
            smallRow: true,
            fieldProps: {
                name: 'address1',
                placeholder: 'clinic.enterAddress1',
                required: true,
            },
        },
        {
            id: 10,
            label: translate('clinic.zip'),
            smallRow: true,
            fieldProps: {
                name: 'zip',
                placeholder: 'clinic.enterZip',
                required: true,
            },
        },
        {
            id: 11,
            label: translate('clinic.address2'),
            smallRow: true,
            fieldProps: {
                name: 'address2',
                placeholder: 'clinic.enterAddress2',
                required: true,
            },
        },
        {
            id: 12,
            label: translate('clinic.phoneNumber'),
            smallRow: true,
            fieldProps: {
                name: 'phone',
                placeholder: 'clinic.enterPhoneNumber',
                required: true,
            },
        },
        {
            id: 13,
            label: translate('clinic.lat'),
            smallRow: true,
            fieldProps: {
                name: 'lat',
                placeholder: 'clinic.enterLat',
                required: true,
            },
        },
        {
            id: 14,
            label: translate('clinic.long'),
            smallRow: true,
            fieldProps: {
                name: 'long',
                placeholder: 'clinic.enterLong',
                required: true,
            },
        },
        // {
        //     id: 4,
        //     label: translate('clinic.opusDentalKey'),
        //     fieldProps: {
        //         name: 'opusDentalKey',
        //         placeholder: 'clinic.enterOpusDentalKey',
        //         required: true,
        //     },
        // },
        {
            id: 5,
            label: translate('clinic.opusDentalClinicID'),
            smallRow: true,
            fieldProps: {
                name: 'opus_clinic_id',
                placeholder: 'clinic.enterOpusDentalClinicID',
            },
        },
        // {
        //     id: 6,
        //     label: translate('clinic.googlePlaceID'),
        //     fieldProps: {
        //         name: 'googlePlaceID',
        //         placeholder: 'clinic.enterGooglePlaceID',
        //     },
        // },
        {
            id: 55,
            label: translate('clinic.clinicEmail'),
            smallRow: true,
            fieldProps: {
                name: 'email',
                placeholder: 'clinic.enterClinicEmail',
            },
        },
        {
            id: 56,
            label: translate('clinic.penaltyFee'),
            smallRow: true,
            fieldProps: {
                name: 'penalty_fee',
                placeholder: 'clinic.enterPenaltyFee',
            },
        },
        {
            id: 57,
            label: translate('clinic.group'),
            smallRow: true,
            fieldProps: {
                name: 'group',
                placeholder: 'clinic.enterClinicGroup',
            },
        },
    ]
}

export const ClinicTipsFormConfig = {
    showTips: [
        {
            id: 0,
            label: translate('treatment.showTips'),
            element: Select,
            fieldProps: {
                defaultValue: trueFalseOptions[1],
                name: 'showTips',
                placeholder: 'treatment.selectOption',
                required: true,
                className: 'booking-select',
                classNamePrefix: 'custom-select',
                options: trueFalseOptions,
                isSearchable: false,
            },
        },
    ],
    tips: [
        {
            id: getKey(),
            label: translate('treatment.tipText'),
            isTip: true,
            tipNumber: 1,
            fieldProps: {
                name: 'tipText',
                placeholder: 'treatment.enterTipText',
                required: true,
            },
        },
    ],
}
