import Clinic from '@containers/clinic/index'
import CreateClinic from '@containers/createClinic/index'
import EditClinic from '@containers/editClinic/index'
import { Routes } from '@shared/constants/routes'

export const clinicRoutes = [
    {
        routeParams: {
            path: Routes.clinic,
            exact: true,
        },
        component: Clinic,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.createClinic,
        },
        component: CreateClinic,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.editClinic,
        },
        component: EditClinic,
        isPrivate: true,
    },
]
