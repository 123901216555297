import React from 'react'
import List from '@material-ui/core/List'
import {
    NavTitle,
    LinkContent,
    NavSubtitle,
    StyledNavLink,
} from './styledComponents'
import { logout } from '@store/reducers/authorization'
import { useHistory } from 'react-router'
import { Routes } from '@shared/constants/routes'
import { withConnectedStore } from '@hocs/withConnectedStore'

const SidebarNavigation = ({ config, dispatch }) => {
    const history = useHistory()

    const handleLogout = async e => {
        e.preventDefault()

        try {
            await dispatch(logout())
            history.push(Routes.login)
        } catch (error) {}
    }

    return (
        <List>
            {config.map(({ isLogout, subtitle, title, route, icon }) => {
                const NavIcon = icon

                return (
                    <StyledNavLink
                        to={route}
                        key={route}
                        onClick={isLogout && handleLogout}
                        activeClassName="activeLink"
                    >
                        <LinkContent>
                            <NavIcon />
                            <NavTitle>{title}</NavTitle>
                        </LinkContent>
                        {subtitle && <NavSubtitle>{subtitle}</NavSubtitle>}
                    </StyledNavLink>
                )
            })}
        </List>
    )
}

const withStore = withConnectedStore(SidebarNavigation)

export { withStore as SidebarNavigation }
