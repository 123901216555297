import { envConfig } from '@infrastructure/envConfig'

export class AuthorizationUrlProvider {
    baseUrl = `${envConfig.API_URL}`

    loginUrl = () => `${this.baseUrl}/login`

    logoutUrl = () => `${this.baseUrl}/logout`

    currentUserUrl = () => `${this.baseUrl}/me`

    forgotPasswordUrl = () => `${this.baseUrl}/password/forgot`

    resetPasswordUrl = () => `${this.baseUrl}/password/reset`

    profileUrl = () => `${this.baseUrl}/profile`
}
