import React from 'react'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { ClinicsContent } from './ClinicsContent'
import { Title } from './Title'
import { Row } from '@shared/components/Table/Raw'
import { TableContentContainer } from '@shared/components/Table/styledComponents'
import Box from '@material-ui/core/Box'
import { translate } from '@utils/translationUtils'
import { getRouteWithSlug, getRouteWithRegionId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { getKey } from '@utils/arrayUtils'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'
import { checkAccess } from '@utils/userUtils'

export const RegionsContent = ({ slug, regions, userPermissions }) => {
    const canCreateRegion = checkAccess(
        userPermissions,
        UserPermissionsEnum.REGION_CREATE
    )

    const canEditRegion = checkAccess(
        userPermissions,
        UserPermissionsEnum.REGION_UPDATE
    )

    return (
        <TableContentContainer>
            {regions?.map(region => {
                const { id, name, clinics } = region

                return (
                    <Box key={getKey()} width="100%" marginBottom={3}>
                        <Row
                            firstCellContent={<Title text={name} />}
                            secondCellContent={
                                canEditRegion &&
                                name !== 'No Region' && (
                                    <UnderlinedLink
                                        text={translate('region.editRegion')}
                                        url={getRouteWithRegionId(
                                            Routes.editRegion,
                                            id
                                        )}
                                    />
                                )
                            }
                            marginTop={1}
                        />

                        <ClinicsContent {...{ clinics }} />
                    </Box>
                )
            })}

            {canCreateRegion && (
                <UnderlinedLink
                    text={translate('region.addNew')}
                    url={getRouteWithSlug(Routes.createRegion, slug)}
                />
            )}
        </TableContentContainer>
    )
}
