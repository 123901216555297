import { translate } from '@utils/translationUtils'

export const CreateRegionFormConfig = [
    {
        id: 0,
        label: translate('region.regionName'),
        fieldProps: {
            name: 'name',
            placeholder: 'region.enterRegionName',
            required: true,
        },
    },
]
