import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/Form/Form'
import { CategoryFormConfig } from './configurations/CategoryFormConfig'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { Routes } from '@shared/constants/routes'
import { useHistory, useParams } from 'react-router'
import { CategoryFormValidator } from './configurations/CategoryFormValidators'
import { createCategory } from '@store/reducers/treatments'
import { getRouteWithId } from '@utils/routeUtils'

const CreateCategory = ({ dispatch, store }) => {
    const { id } = useParams()
    const history = useHistory()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CategoryFormValidator),
    })

    const { loading } = store.treatments

    const onSubmit = async data => {
        try {
            await dispatch(createCategory(data, id))
            showSuccessMessage('notification.categoryCreated')

            history.push(getRouteWithId(Routes.treatmentsCategories, id))
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('treatment.createCategory')} />

            <Form
                {...{
                    errors,
                    control,
                    isDisabled: false,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                    formConfig: CategoryFormConfig,
                }}
            />
        </SidebarLayout>
    )
}

export default withConnectedStore(CreateCategory)
