import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { baseActions } from '@store/baseActions'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { ClinicsUrlProvider } from '@store/apiUrlProviders/ClinicsUrlProvider'
import { ChainsUrlProvider } from '@store/apiUrlProviders/ChainsUrlProvider'
import { getEncodedArray, getFormData } from '@utils/requestUtils'
import { baseReducer } from '@store/baseReducers'

const authorizationProvider = new AuthorizationProvider()
const clinicsUrlProvider = new ClinicsUrlProvider()
const chainsUrlProvider = new ChainsUrlProvider()

const initialState = {
    loading: false,
    clinic: {
        chain: { id: null, name: '' },
        region: { id: '', name: '' },
        opus_clinic_id: '',
        cancellation_time: null,
        country: '',
        email: '',
        city: '',
        id: null,
        name: '',
        group: '',
        address1: '',
        address2: '',
        type: '',
        zip: '',
    },
    chainClinics: [],
}

// ACTIONS
export const getClinicInfo = createAction('GET_CLINIC_INFO', id => ({
    payload: Axios.get(
        clinicsUrlProvider.clinicUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const createClinic = createAction('CREATE_CLINIC', (data, slug) => {
    if (!data.region_id.value) {
        delete data.region_id
    }
    const formData = getFormData(data)

    getEncodedArray(data, formData, 'tips')

    return {
        payload: Axios.post(
            chainsUrlProvider.chainClinicsUrl(slug),
            formData,
            authorizationProvider.getAuthHeaders()
        ),
    }
})

export const deleteClinic = createAction('DELETE_CLINIC', id => ({
    payload: Axios.delete(
        clinicsUrlProvider.clinicUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const updateClinic = createAction('UPDATE_CLINIC', (data, id) => {
    if (!data.region_id.value) {
        delete data.region_id
    }
    data._method = 'PUT'

    const formData = getFormData(data)

    getEncodedArray(data, formData, 'tips')

    return {
        payload: Axios.post(
            clinicsUrlProvider.clinicUrl(id),
            formData,
            authorizationProvider.getAuthHeaders()
        ),
    }
})

export const getChainClinics = createAction('GET_CHAIN_CLINICS', slug => ({
    payload: Axios.get(
        chainsUrlProvider.chainClinicsUrl(slug),
        authorizationProvider.getAuthHeaders()
    ),
}))

// REDUCERS
const reducer = {
    [getClinicInfo]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            loading: false,
            clinic: payload.data,
        }),
    },
    [getChainClinics]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            loading: false,
            chainClinics: payload.data,
        }),
    },
    [createClinic]: baseReducer,
    [deleteClinic]: baseReducer,
    [updateClinic]: baseReducer,
}

export default typeToReducer(reducer, initialState)
