import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { toastConfig } from '@utils/notificationUtils'
import configureAxios from '@infrastructure/configureAxios'
import { useDispatch } from 'react-redux'

export const withGlobalConfigs = WrappedComponent => {
    const Component = props => {
        const dispatch = useDispatch()

        useEffect(() => {
            toast.configure(toastConfig)
            configureAxios(dispatch)
        }, [])

        useEffect(() => {}, [])

        return <WrappedComponent {...props} />
    }

    return Component
}
