import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'

const AccountSettings = () => {
    return (
        <SidebarLayout>
            <h1>Account Settings</h1>
        </SidebarLayout>
    )
}

export default AccountSettings
