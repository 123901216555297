import React from 'react'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import Box from '@material-ui/core/Box'
import { deleteUser } from '@store/reducers/users'
import { Routes } from '@shared/constants/routes'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { checkAccess } from '@utils/userUtils'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'

export const DeleteUserBlock = ({ user, userId, history, dispatch }) => {
    const onUserDelete = async () => {
        try {
            await dispatch(deleteUser(userId))
            showSuccessMessage('notification.userDeleted')

            history.push(Routes.users)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const hasAccessToDelete = checkAccess(
        user.permissions,
        UserPermissionsEnum.USER_DELETE
    )

    if (!hasAccessToDelete) {
        return null
    }

    return (
        <Box marginTop={2}>
            <UnderlinedButton
                onClick={onUserDelete}
                translationKey={'users.delete'}
            />
        </Box>
    )
}
