import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '@shared/constants/colors'
import { appBarHeight, sidebarWidth } from '@shared/constants/styleConstants'

const appHeaderStyles = makeStyles(() => ({
    appBar: {
        display: 'flex',
        justifyContent: 'center',
        width: `calc(100% - ${sidebarWidth}px)`,
        marginLeft: sidebarWidth,
        color: Colors.gray[300],
        backgroundColor: Colors.gray[150],
        boxShadow: 'none',
        height: appBarHeight,
    },
}))

export { appHeaderStyles }
