import styled from '@emotion/styled'
import { Colors } from '@shared/constants/colors'

const StyledTitle = styled.h5`
    font-size: 20px;
    font-weight: ${({ regular, semibold }) =>
        regular ? '400' : semibold ? '600' : '700'};
    color: ${Colors.gray[300]};
    text-align: ${({ right }) => (right ? 'right' : 'left')};
`

const StyledTitleWrapper = styled.div`
    display: flex;
    align-items: center;
`

const StyledTitleImg = styled.div`
    width: ${({ circle }) => (circle ? '70px' : '85px')};
    height: ${({ circle }) => (circle ? '70px' : '35px')};
    margin-right: 15px;
    border-radius: ${({ circle }) => (circle ? '50%' : '0')};
    overflow: hidden;
    background: url(${({ src }) => src}) center / cover;
`

export { StyledTitle, StyledTitleImg, StyledTitleWrapper }
