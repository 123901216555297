import AccountSettings from '@containers/accountSettings/index'
import ClinicSettings from '@containers/clinicSettings/index'
import Profile from '@containers/profile/index'
import { Routes } from '@shared/constants/routes'

export const otherRoutes = [
    {
        routeParams: {
            path: Routes.settings,
        },
        component: ClinicSettings,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.accountSettings,
        },
        component: AccountSettings,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.profile,
        },
        component: Profile,
        isPrivate: true,
    },
]
