import React, { useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { PractitionerForm } from './components/PractitionerForm'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PractitionerFormValidators } from './configurations/PractitionerFormValidators'
import { createPractitioner } from '@store/reducers/practitioners'
import { useHistory, useParams } from 'react-router'
import { Routes } from '@shared/constants/routes'
import { getRouteWithId } from '@utils/routeUtils'

const CreatePractitioner = ({ store, dispatch }) => {
    const loading = store.practitioners.loading

    const history = useHistory()
    const { id } = useParams()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(PractitionerFormValidators),
    })

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isSizeError, setSizeError] = useState(false)

    const onSubmit = async data => {
        try {
            if (selectedFile) {
                data.file = selectedFile
            }
            await dispatch(createPractitioner(data, id))

            history.push(getRouteWithId(Routes.practitioners, id))
            showSuccessMessage('notification.practitionerCreated')
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('practitioner.newPractitioner')}
            />

            <PractitionerForm
                {...{
                    errors,
                    control,
                    onSubmit,
                    isSizeError,
                    setSizeError,
                    handleSubmit,
                    selectedImage,
                    setSelectedImage,
                    setSelectedFile,
                }}
            />
        </SidebarLayout>
    )
}

export default withConnectedStore(CreatePractitioner)
