import { translate } from '@utils/translationUtils'

export const LoginFormConfig = [
    {
        id: 0,
        label: translate('form.userName'),
        fieldProps: {
            name: 'email',
            placeholder: 'form.enterUserName',
            required: true,
        },
    },
    {
        id: 1,
        label: translate('form.password'),
        fieldProps: {
            name: 'password',
            placeholder: 'form.enterPassword',
            required: true,
            type: 'password',
        },
    },
]
