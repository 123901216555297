import CreateRegion from '@containers/createRegion/index'
import EditRegion from '@containers/editRegion/index'
import { Routes } from '@shared/constants/routes'

export const regionRoutes = [
    {
        routeParams: {
            path: Routes.createRegion,
        },
        component: CreateRegion,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.editRegion,
        },
        component: EditRegion,
        isPrivate: true,
    },
]
