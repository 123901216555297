export const getMultipartFormHeaders = () => ({
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

export const getEncodedFormData = data =>
    Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')

export const getFormData = data => {
    const formData = new FormData()

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData.append(key, element?.value || element)
        }
    }

    return formData
}

export const getEncodedArray = (data, formData, key) => {
    const dataArray = data[key]

    formData.delete(key)

    dataArray.forEach((valueData, index) => {
        for (const valueKey in valueData) {
            if (Object.prototype.hasOwnProperty.call(valueData, valueKey)) {
                const element = valueData[valueKey]
                
                if (element) {
                    formData.append(`${key}[${index}][${valueKey}]`, element)
                }
            }
        }
    })
}

export const getJSONData = data => {
    const formData = {}

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData[key] =
                element?.value === undefined ? element : element?.value
        }
    }

    return JSON.stringify(formData)
}
