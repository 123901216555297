import { LanguagesEnum } from '@enums/LanguagesEnum'
import { getTimezonesArray } from '@utils/arrayUtils'
import { translate } from '@utils/translationUtils'
import Select from 'react-select'

export const timezoneOptions = getTimezonesArray().map(timezone => ({
    value: timezone,
    label: timezone,
}))

export const languageOptions = [
    { value: LanguagesEnum.ENGLISH, label: 'English' },
    { value: LanguagesEnum.DANISH, label: 'Danish' },
    { value: LanguagesEnum.NORWEGIAN, label: 'Norwegian' },
]

export const CreateChainFormConfig = [
    {
        id: 0,
        label: translate('chain.chainName'),
        fieldProps: {
            name: 'name',
            placeholder: 'chain.enterChainName',
            required: true,
        },
    },
    {
        id: 1,
        label: translate('chain.language'),
        element: Select,
        fieldProps: {
            name: 'language',
            placeholder: 'chain.selectLanguage',
            className: 'booking-select',
            classNamePrefix: 'custom-select',
            options: languageOptions,
            isSearchable: false,
        },
    },
    {
        id: 2,
        label: translate('chain.timezone'),
        element: Select,
        fieldProps: {
            name: 'timezone',
            placeholder: 'chain.selectTimezone',
            className: 'booking-select',
            classNamePrefix: 'custom-select',
            options: timezoneOptions,
            isSearchable: false,
        },
    },
]
