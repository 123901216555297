import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import { StyledImagePicker } from './styledComponents'
import { UnderlinedButton } from '../Buttons/UnderlinedButton'
import { InputError, InputLabel } from '../StyledInput/styledComponents'
import { translate } from '@utils/translationUtils'

export const ImagePicker = ({
    label,
    isSizeError,
    setSizeError,
    selectedImage,
    setSelectedFile,
    setSelectedImage,
    imagePickerHeight = null,
}) => {
    const filePickerRef = useRef(null)

    const triggerFilePicker = () => filePickerRef.current.click()

    const onImageChange = ({ target }) => {
        const MAX_SIZE = 10000000
        const file = target.files[0]

        if (file) {
            setSizeError(file.size > MAX_SIZE)

            const reader = new FileReader()
            reader.readAsDataURL(file)

            setSelectedFile(file)

            reader.addEventListener('load', event => {
                setSelectedImage(event.target.result)
            })
        }
    }

    const onImageDelete = () => {
        setSizeError(false)
        setSelectedFile(null)
        setSelectedImage(null)
        filePickerRef.current.value = ''
    }

    return (
        <Box marginTop={3} marginBottom={3}>
            <InputLabel>{translate(label)}</InputLabel>

            <StyledImagePicker
                src={encodeURI(selectedImage)}
                height={imagePickerHeight}
            />

            <Box display="flex" justifyContent="Space-between">
                <UnderlinedButton
                    onClick={triggerFilePicker}
                    translationKey={selectedImage ? 'replace' : 'addNew'}
                />

                {selectedImage && (
                    <UnderlinedButton
                        onClick={onImageDelete}
                        translationKey={'remove'}
                    />
                )}
            </Box>

            {isSizeError && (
                <InputError>
                    {translate('notification.maxSizeImage')}
                </InputError>
            )}

            <Box display="none">
                <input
                    ref={filePickerRef}
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                />
            </Box>
        </Box>
    )
}
