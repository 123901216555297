import { statusOptions } from '@containers/createUser/configurations/CreateUserFormConfig'
import { TextArea } from '@shared/components/StyledInput/styledComponents'
import { translate } from '@utils/translationUtils'
import Select from 'react-select'

export const PractitionerFormConfig = [
    {
        id: 1,
        label: translate('practitioner.name'),
        fieldProps: {
            name: 'name',
            placeholder: 'practitioner.enterName',
            required: true,
        },
    },
    {
        id: 2,
        label: translate('practitioner.title'),
        fieldProps: {
            name: 'title',
            placeholder: 'practitioner.enterTitle',
            required: true,
        },
    },
    {
        id: 3,
        label: translate('practitioner.education'),
        fieldProps: {
            name: 'education',
            placeholder: 'practitioner.enterEducation',
            required: true,
        },
    },
    {
        id: 4,
        label: translate('practitioner.biography'),
        fieldProps: {
            name: 'biography',
            placeholder: 'practitioner.enterBiography',
            required: true,
            as: TextArea,
        },
    },
    {
        id: 5,
        label: translate('practitioner.opusDentalClinicianId'),
        fieldProps: {
            name: 'opus_clinician_id',
            placeholder: 'practitioner.enterClinicianID',
            required: true,
        },
    },
    {
        id: 6,
        label: translate('practitioner.status'),
        element: Select,
        fieldProps: {
            name: 'status',
            placeholder: 'practitioner.selectStatus',
            required: true,
            className: 'booking-select',
            classNamePrefix: 'custom-select',
            options: statusOptions,
            isSearchable: false,
        },
    },
]
