import { envConfig } from '@infrastructure/envConfig'

export class ChainsUrlProvider {
    baseUrl = `${envConfig.API_URL}`

    chainsUrl = () => `${this.baseUrl}/chains`

    chainDetailsUrl = slug => `${this.baseUrl}/chains/${slug}`

    chainClinicsUrl = slug => `${this.baseUrl}/chains/${slug}/clinics`

    updateChainUrl = chainId => `${this.baseUrl}/chains/${chainId}`
}
