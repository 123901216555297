import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { baseActions } from '@store/baseActions'
import {
    getFormData,
    getJSONData,
    getMultipartFormHeaders,
} from '@utils/requestUtils'
import { AuthorizationUrlProvider } from '@store/apiUrlProviders/AuthorizationUrlProvider'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { baseReducer } from '@store/baseReducers'
import { deleteEmptyValues } from '@utils/objectUtils'

const authorizationUrlProvider = new AuthorizationUrlProvider()
const authorizationProvider = new AuthorizationProvider()

const initialState = {
    loading: false,
    isAuthorized: false,
    user: {
        name: null,
        email: null,
        roles: [],
        permissions: [],
    },
}

// ACTIONS
export const login = createAction('LOGIN_USER', data => {
    const formData = getFormData(data)

    return {
        payload: Axios.post(
            authorizationUrlProvider.loginUrl(),
            formData,
            getMultipartFormHeaders()
        ),
    }
})

export const logout = createAction('LOGOUT_USER', () => ({
    payload: Axios.post(
        authorizationUrlProvider.logoutUrl(),
        null,
        authorizationProvider.getAuthHeaders()
    ),
}))

export const logoutWithoutToken = createAction('LOGOUT_WITHOUT_TOKEN')

export const forgotPassword = createAction('FORGOT_PASSWORD', data => {
    const formData = getFormData(data)

    return {
        payload: Axios.post(
            authorizationUrlProvider.forgotPasswordUrl(),
            formData
        ),
    }
})

export const resetPassword = createAction('RESET_PASSWORD', data => {
    const formData = getFormData(data)

    return {
        payload: Axios.post(
            authorizationUrlProvider.resetPasswordUrl(),
            formData
        ),
    }
})

export const getCurrentUserInfo = createAction('CURRENT_USER_INFO', () => ({
    payload: Axios.get(
        authorizationUrlProvider.currentUserUrl(),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const updateProfile = createAction('UPDATE_PROFILE', data => {
    const formattedData = deleteEmptyValues(data)
    const formData = getJSONData(formattedData)

    return {
        payload: Axios.put(
            authorizationUrlProvider.profileUrl(),
            formData,
            authorizationProvider.getAuthHeaders({ defaultType: true })
        ),
    }
})

// REDUCERS
const reducer = {
    [login]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => {
            const { token, user } = payload.data
            authorizationProvider.setToken(token)

            return {
                ...state,
                user: { ...user, chains: user.chains || [] },
                isAuthorized: true,
                loading: false,
            }
        },
    },
    [logout]: {
        ...baseActions,
        FULFILLED: () => {
            authorizationProvider.removeToken()

            return initialState
        },
    },
    [logoutWithoutToken]: () => {
        authorizationProvider.removeToken()

        return initialState
    },
    [forgotPassword]: baseReducer,
    [resetPassword]: baseReducer,
    [updateProfile]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            user: {
                ...state.user,
                name: payload.data.name,
            },
            loading: false,
        }),
    },
}

export default typeToReducer(reducer, initialState)
