import { translate } from '@utils/translationUtils'

export const ResetFormConfig = [
    {
        id: 0,
        label: translate('form.newPassword'),
        fieldProps: {
            name: 'password',
            placeholder: 'form.enterPassword',
            required: true,
            type: 'password',
        },
    },
    {
        id: 1,
        label: translate('form.confirmPassword'),
        fieldProps: {
            name: 'password_confirmation',
            placeholder: 'form.confirmPassword',
            required: true,
            type: 'password',
        },
    },
]
