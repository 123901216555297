import { Routes } from '@shared/constants/routes'
import { logoutWithoutToken } from '@store/reducers/authorization'
import axios from 'axios'

const wrongCredentialsMsg = 'Invalid login details'

export default async function (dispatch) {
    axios.interceptors.response.use(
        response => {
            return response
        },
        error => {
            if (
                error.response?.status === 401 &&
                error.response?.data.message !== wrongCredentialsMsg
            ) {
                dispatch(logoutWithoutToken())
                window.location.replace(Routes.login)
            }
            return Promise.reject(error)
        }
    )
}
