import { string, object } from 'yup'

export const CreateChainValidator = object().shape({
    name: string().required('Chain name is required').min(1).max(50),
    timezone: object()
        .shape({
            label: string().required('Timezone is required'),
            value: string().required('Timezone is required'),
        })
        .typeError('Timezone is required'),
    language: object()
        .shape({
            label: string().required('Language is required'),
            value: string().required('Language is required'),
        })
        .typeError('Language is required'),
})
