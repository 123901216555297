export const Colors = {
    primary: '#219BA7',
    secondary: '#114E54',
    white: '#FFF',

    gray: {
        // placeholder
        50: '#D6D6D6',

        100: '#F8F8F8',
        150: '#F1F1F1',
        // border
        200: '#707070',
        // additional text
        250: '#878E97',
        // text
        300: '#666E78',
    },
}
