import React from 'react'
import { TableContainer } from '@shared/components/Table/styledComponents'
import { Users } from './Users'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { UserRolesEnum } from '@enums/UserRolesEnum'

const UsersTable = ({ store, users }) => {
    const { superUsers, regularUsers, chainAdministrators } = users

    const userRoles = store.auth.user.roles

    const userRole = userRoles[0]

    const isChainAdmin = userRole === UserRolesEnum.CHAIN_ADMIN

    return (
        <TableContainer withoutunderline>
            <Users
                users={superUsers}
                columnTitleKey={'users.superusers'}
                withoutEditing
            />
            <Users
                users={chainAdministrators}
                columnTitleKey={'users.chainAdministrators'}
            />
            <Users
                users={regularUsers}
                columnTitleKey={'users.regularUsers'}
                isChainAdmin={isChainAdmin}
            />
        </TableContainer>
    )
}

const withStore = withConnectedStore(UsersTable)

export { withStore as UsersTable }
