import React from 'react'
import { useForm } from 'react-hook-form'
import { login } from '@store/reducers/authorization'
import { LoginFormConfig } from './configurations/LoginFormConfig'
import { ForgotPasswordLink } from './styledComponents'
import { Form } from '@shared/components/Form/Form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginFormValidators } from './configurations/LoginValidationConfig'
import { translate } from '@utils/translationUtils'
import { useHistory } from 'react-router'
import { Routes } from '@shared/constants/routes'
import { AuthLayout } from '@shared/components/AuthLayout/AuthLayout'
import { showErrorMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'

const Login = ({ dispatch }) => {
    const history = useHistory()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginFormValidators),
    })

    const onSubmit = async data => {
        try {
            await dispatch(login(data))
            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error, 'notification.wrongCredentials')
        }
    }

    return (
        <AuthLayout formTitle={translate('form.login')}>
            <>
                <Form
                    errors={errors}
                    control={control}
                    buttonText={translate('form.login')}
                    onConfirm={handleSubmit(onSubmit)}
                    formConfig={LoginFormConfig}
                    fullWidthForm
                />

                <ForgotPasswordLink to={Routes.forgotPassword}>
                    {translate('forgotPassword')}?
                </ForgotPasswordLink>
            </>
        </AuthLayout>
    )
}

export default withConnectedStore(Login)
