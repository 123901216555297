export class AuthorizationProvider {
    tokenKey = 'auth_token'

    setToken = token => localStorage.setItem(this.tokenKey, token)
    getToken = () => localStorage.getItem(this.tokenKey)
    removeToken = () => localStorage.removeItem(this.tokenKey)

    // Auth API headers
    getAuthHeaders = options => ({
        headers: {
            Accept: 'application/json',
            'Content-Type': options?.urlencoded
                ? 'application/x-www-form-urlencoded'
                : options?.defaultType
                ? 'application/json;charset=utf-8'
                : 'multipart/form-data',
            Authorization: `Bearer ${this.getToken()}`,
        },
    })
}
