import React from 'react'
import {
    StyledTitle,
    StyledTitleImg,
    StyledTitleWrapper,
} from '../styledComponents'

export const Title = ({ isRoundImage = false, text, imageSrc, isSemiBold }) => (
    <StyledTitleWrapper>
        {imageSrc && (
            <StyledTitleImg src={encodeURI(imageSrc)} circle={isRoundImage} />
        )}
        <StyledTitle semibold={isSemiBold}>{text}</StyledTitle>
    </StyledTitleWrapper>
)
