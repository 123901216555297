import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { UsersTable } from './components/UsersTable'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { getUsers } from '@store/reducers/users'
import { Routes } from '@shared/constants/routes'
import { UserRolesEnum } from '@enums/UserRolesEnum'

const Users = ({ store, dispatch }) => {
    const { users, loading } = store.users
    const currentUser = store.auth.user

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const isSuperAdmin = currentUser.roles[0] === UserRolesEnum.SUPER_ADMIN

    const canCreateUser =
        (currentUser.roles[0] !== UserRolesEnum.REGULAR_USER &&
            currentUser.chains?.length) ||
        isSuperAdmin

    const addUserTitle = isSuperAdmin ? 'users.addChainAdmin' : 'users.addUser'

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('users.users')}
                navigateTo={Routes.addUser}
                hiddenButton={!canCreateUser}
                navBtnText={translate(addUserTitle)}
            />

            <UsersTable users={users} />
        </SidebarLayout>
    )
}

export default withConnectedStore(Users)
