import React from 'react'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Row } from '@shared/components/Table/Raw'
import { Routes } from '@shared/constants/routes'
import { getRouteWithSlug } from '@utils/routeUtils'
import { translate } from '@utils/translationUtils'
import { Title } from './Title'
import { checkAccess } from '@utils/userUtils'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'

export const ChainHeaderRow = ({ name, image, slug, userPermissions }) => {
    const canUpdateChain = checkAccess(
        userPermissions,
        UserPermissionsEnum.CHAIN_CREATE
    )

    const canCreateClinic = checkAccess(
        userPermissions,
        UserPermissionsEnum.CLINIC_CREATE
    )

    return (
        <Row
            firstCellContent={<Title text={name} imageSrc={image} />}
            secondCellContent={
                canUpdateChain && (
                    <UnderlinedLink
                        text={translate('chain.view')}
                        url={getRouteWithSlug(Routes.viewChain, slug)}
                    />
                )
            }
            thirdCellContent={
                canUpdateChain && (
                    <UnderlinedLink
                        text={translate('chain.edit')}
                        url={getRouteWithSlug(Routes.editChain, slug)}
                    />
                )
            }
            forthCellContent={
                canCreateClinic && (
                    <UnderlinedLink
                        text={translate('clinic.addClinic')}
                        url={getRouteWithSlug(Routes.createClinic, slug)}
                        rightAlign="right"
                    />
                )
            }
        />
    )
}
