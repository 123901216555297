import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { baseActions } from '@store/baseActions'
import { ChainsUrlProvider } from '@store/apiUrlProviders/ChainsUrlProvider'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { getFormData } from '@utils/requestUtils'
import { baseReducer } from '@store/baseReducers'

const chainsUrlProvider = new ChainsUrlProvider()
const authorizationProvider = new AuthorizationProvider()

const initialState = {
    loading: false,
    chains: [],
    chainDetails: {},
}

// ACTIONS
export const loadChains = createAction('LOAD_CHAINS', () => {
    return {
        payload: Axios.get(
            chainsUrlProvider.chainsUrl(),
            authorizationProvider.getAuthHeaders()
        ),
    }
})

export const createChain = createAction('CREATE_CHAIN', data => {
    const formData = getFormData(data)

    return {
        payload: Axios.post(
            chainsUrlProvider.chainsUrl(),
            formData,
            authorizationProvider.getAuthHeaders()
        ),
    }
})

export const getChainDetails = createAction('GET_CHAIN_DETAILS', slug => ({
    payload: Axios.get(
        chainsUrlProvider.chainDetailsUrl(slug),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const deleteChain = createAction('DELETE_CHAIN', id => ({
    payload: Axios.delete(
        chainsUrlProvider.updateChainUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const updateChain = createAction('UPDATE_CHAIN', (data, id) => {
    const formData = getFormData(data)

    return {
        payload: Axios.post(
            chainsUrlProvider.updateChainUrl(id),
            formData,
            authorizationProvider.getAuthHeaders()
        ),
    }
})

// REDUCERS
const reducer = {
    [loadChains]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                chainDetails: {},
                chains: payload.data,
            }
        },
    },
    [getChainDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            chainDetails: payload.data,
            loading: false,
        }),
    },
    [createChain]: baseReducer,
    [deleteChain]: baseReducer,
    [updateChain]: baseReducer,
}

export default typeToReducer(reducer, initialState)
