import './i18n/i18n'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { history, configureStore, createPersistor } from '@store/store'
import App from '@containers/app/index'
import { PersistGate } from 'redux-persist/integration/react'

import 'react-toastify/dist/ReactToastify.css'
import 'sanitize.css/sanitize.css'
import './index.scss'

const initialState = window.initialReduxState
const store = configureStore(history, initialState)

const persistor = createPersistor(store)

const rootElement = document.getElementById('root')

render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    rootElement
)
