import CreatePractitioner from '@containers/createPractitioner/index'
import EditPractitioner from '@containers/editPractitioner/index'
import Practitioners from '@containers/practitioners/index'
import { Routes } from '@shared/constants/routes'

export const practitionerRoutes = [
    {
        routeParams: {
            path: Routes.practitionersCreate,
        },
        component: CreatePractitioner,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.practitionersEdit,
            exact: true,
        },
        component: EditPractitioner,
        isPrivate: true,
    },
    {
        routeParams: {
            path: Routes.practitioners,
        },
        component: Practitioners,
        isPrivate: true,
    },
]
