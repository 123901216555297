import { UserStatusesEnum } from '@enums/UserStatutesEnum'
import { translate } from '@utils/translationUtils'
import Select from 'react-select'

export const statusOptions = [
    { value: UserStatusesEnum.ACTIVATED, label: 'Activated' },
    { value: UserStatusesEnum.DEACTIVATED, label: 'Deactivated' },
]

export const getCreateUserFormConfig = (
    isChainAdmin,
    accessOptions,
    isEditMode = false
) => {
    return [
        {
            id: 0,
            label: translate('users.name'),
            fieldProps: {
                name: 'name',
                placeholder: 'users.enterName',
                required: true,
            },
        },
        {
            id: 1,
            label: translate('users.email'),
            fieldProps: {
                disabled: isEditMode,
                name: 'email',
                placeholder: 'users.enterEmail',
                required: true,
            },
        },
        {
            id: 2,
            label: translate('users.status'),
            element: Select,
            fieldProps: {
                name: 'status',
                placeholder: 'users.selectStatus',
                required: true,
                className: 'booking-select',
                classNamePrefix: 'custom-select',
                options: statusOptions,
                isSearchable: false,
            },
        },
        {
            id: 3,
            label: translate('users.grantAccess'),
            element: Select,
            fieldProps: {
                isMulti: isChainAdmin,
                name: isChainAdmin ? 'clinics' : 'chain',
                placeholder: isChainAdmin
                    ? 'users.selectClinics'
                    : 'users.selectChain',
                required: true,
                className: 'booking-select',
                classNamePrefix: 'custom-select',
                options: accessOptions,
                isSearchable: false,
            },
        },
    ]
}
