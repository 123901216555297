import React from 'react'
import { StyledLink, StyledLinkText } from './styledComponents'

export const UnderlinedLink = ({ url, text, rightAlign, svgIcon }) => {
    const SvgComponent = svgIcon

    const handleLinkClick = e => e.stopPropagation()

    return (
        <StyledLink to={url} onClick={handleLinkClick} rightalign={rightAlign}>
            <StyledLinkText>{text}</StyledLinkText>
            {svgIcon && <SvgComponent />}
        </StyledLink>
    )
}
