import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import authorization from './authorization'
import practitioners from './practitioners'
import treatments from './treatments'
import regions from './regions'
import clinics from './clinics'
import chains from './chains'
import users from './users'

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['loading'],
}

export const getReducers = history =>
    combineReducers({
        router: connectRouter(history),
        auth: persistReducer(authPersistConfig, authorization),
        practitioners,
        treatments,
        regions,
        clinics,
        chains,
        users,
    })
