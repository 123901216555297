import { envConfig } from '@infrastructure/envConfig'

export class RegionsUrlProvider {
    baseUrl = `${envConfig.API_URL}`

    regions = () => `${this.baseUrl}/regions`

    regionById = id => `${this.regions()}/${id}`

    createRegion = chainSlug => `${this.baseUrl}/chains/${chainSlug}/regions`
}
