import React, { useEffect, useState } from 'react'
import { translate } from '@utils/translationUtils'
import { TreatmentFormDataProvider } from 'providers/treatments/TreatmentFormDataProvider'
import { useParams } from 'react-router'
import { TrueFalseEnum } from '@enums/TrueFalseEnum'
import { getKey } from '@utils/arrayUtils'
import { ClinicTipsFormConfig } from '@containers/createClinic/configurations/CreateClinicFormConfig'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { CreateClinicValidator } from '@containers/createClinic/configurations/CreateClinicFormValidators'
import { ClinicTypeEnum } from '@enums/ClinicTypeEnum'
import { string } from 'yup'

export const withClinicTips = WrappedComponent => {
    const Component = props => {
        const { id } = useParams()

        const maxTipsLength = 10

        const treatmentFormDataProvider = new TreatmentFormDataProvider()

        const { tips, showTips } = ClinicTipsFormConfig

        const [clinicTips, setClinicTips] = useState(id ? [] : tips)

        const [formValidator, setFormValidator] = useState(
            CreateClinicValidator
        )

        const {
            reset,
            watch,
            control,
            register,
            unregister,
            handleSubmit,
            formState: { errors },
        } = useForm({
            resolver: yupResolver(object().shape(formValidator)),
        })

        const clinicTypeValue = watch('type')?.value

        useEffect(() => {
            const updatedFormValidator = formValidator
            if (clinicTypeValue === ClinicTypeEnum.INTEGRATED) {
                updatedFormValidator.opus_clinic_id = string().required(
                    'Opus Dental Clinic ID is required'
                )
            } else {
                delete updatedFormValidator.opus_clinic_id
            }
            setFormValidator(updatedFormValidator)
        }, [clinicTypeValue])

        const addNewTip = () => {
            const firstTip = clinicTips[0]
            const lastTip = { ...clinicTips[clinicTips.length - 1] }

            const nextTipNumber = lastTip.tipNumber + 1

            setClinicTips([
                ...clinicTips,
                {
                    ...lastTip,
                    isTip: true,
                    id: getKey(),
                    tipNumber: nextTipNumber,
                    label: translate(`treatment.tipText${nextTipNumber}`),
                    fieldProps: {
                        ...lastTip.fieldProps,
                        name: `${firstTip.fieldProps.name}${nextTipNumber}`,
                    },
                },
            ])
        }

        const removeTip = tipNum => {
            const filteredTips = clinicTips.filter(
                ({ tipNumber }) => tipNum !== tipNumber
            )
            const fieldNamesForUnregister = clinicTips
                .filter(({ tipNumber }) => tipNum === tipNumber)
                .map(({ fieldProps }) => fieldProps.name)

            setClinicTips(filteredTips)
            unregister(fieldNamesForUnregister)

            const updatedValidators = treatmentFormDataProvider.removeTipsFromValidatorsByNames(
                formValidator,
                fieldNamesForUnregister
            )
            setFormValidator(updatedValidators)
        }

        const showTipsSelectedOption = watch(showTips[0].fieldProps.name)

        const needShowTips =
            showTipsSelectedOption &&
            showTipsSelectedOption.value === TrueFalseEnum.YES

        useEffect(() => {
            if (needShowTips) {
                const updatedValidators = treatmentFormDataProvider.addTipsToValidators(
                    formValidator,
                    clinicTips
                )
                setFormValidator(updatedValidators)
            } else {
                const updatedValidators = treatmentFormDataProvider.removeTipsFromValidators(
                    formValidator,
                    clinicTips
                )
                setFormValidator(updatedValidators)

                const fieldNamesForUnregister = clinicTips.map(
                    ({ fieldProps }) => fieldProps.name
                )
                unregister(fieldNamesForUnregister)
            }
        }, [needShowTips, clinicTips.length])

        return (
            <WrappedComponent
                {...props}
                {...{
                    reset,
                    watch,
                    errors,
                    control,
                    register,
                    removeTip,
                    addNewTip,
                    clinicTips,
                    handleSubmit,
                    needShowTips,
                    formValidator,
                    maxTipsLength,
                    setClinicTips,
                }}
            />
        )
    }

    return Component
}
