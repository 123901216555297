import styled from '@emotion/styled'
import { Colors } from '@shared/constants/colors'

const StyledImagePicker = styled.div`
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : '192px')};
    margin-bottom: 16px;
    background: url(${props => props.src}) center / cover;
    background-color: #dbdbdb;
    border: 1px solid ${Colors.gray[200]};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        display: ${({ src }) => (src ? 'none' : 'block')};
        content: 'NO IMAGE';
    }
`

export { StyledImagePicker }
