import { passwordWithoutEmail } from '@containers/profile/configurations/ProfileValidator'
import { translate } from '@utils/translationUtils'
import { string, object, ref } from 'yup'

export const emailValidator = {
    email: string().email('Email is not valid').required('Email is required'),
}

export const passwordValidator = {
    password: string()
        .min(6, 'Wrong credentials')
        .required('Password is required'),
}

export const strongPasswordValidator = {
    password: string()
        .min(8)
        .max(16)
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
            'Please choose a stronger password. Try a mix of letters (Uppercase and Lowercase), numbers, and symbols.'
        )
        .required('Password is required'),
}

export const passwordConfirmationValidator = {
    password_confirmation: string().oneOf(
        [ref('password'), null],
        translate('Passwords must match')
    ),
}

export const LoginFormValidators = object().shape({
    ...emailValidator,
    ...passwordValidator,
})

export const ForgotFormValidators = object().shape({
    ...emailValidator,
})

export const ResetFormValidators = email =>
    object().shape({
        ...passwordWithoutEmail(email),
    })
