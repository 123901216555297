import { translate } from '@utils/translationUtils'

export const ForgotFormConfig = [
    {
        id: 0,
        label: translate('form.email'),
        fieldProps: {
            name: 'email',
            placeholder: 'form.enterEmail',
            required: true,
        },
    },
]
