import { translate } from '@utils/translationUtils'

export const CategoryFormConfig = [
    {
        id: 0,
        label: translate('treatment.categoryTitle'),
        fieldProps: {
            name: 'name',
            placeholder: 'treatment.enterCategoryTitle',
            required: true,
        },
    },
]
