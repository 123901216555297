import React, { useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/Form/Form'
import { ImagePicker } from '@shared/components/ImagePicker/ImagePicker'
import {
    updateChain,
    deleteChain,
    getChainDetails,
} from '@store/reducers/chains'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { CreateChainValidator } from '@containers/createChain/configurations/ChainFormValidators'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import { Routes } from '@shared/constants/routes'
import Box from '@material-ui/core/Box'
import { getSlugString } from '@utils/stringUtils'
import { checkAccess } from '@utils/userUtils'
import {
    CreateChainFormConfig,
    languageOptions,
    timezoneOptions,
} from '@containers/createChain/configurations/CreateChainFormConfig'
import { UserPermissionsEnum } from '@enums/UserPermissionsEnum'
import { findSelectOptionByValue } from '@utils/arrayUtils'

const EditChain = ({ store, dispatch }) => {
    const { chainDetails, loading } = store.chains
    const { id, name, image, language, timezone } = chainDetails
    const user = store.auth.user

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CreateChainValidator),
    })

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isSizeError, setSizeError] = useState(false)

    const history = useHistory()
    const { slug } = useParams()

    const selectedLanguage = findSelectOptionByValue(languageOptions, language)
    const selectedTimezone = findSelectOptionByValue(timezoneOptions, timezone)

    useEffect(() => {
        dispatch(getChainDetails(slug))
    }, [])

    useEffect(() => {
        reset({ name, language: selectedLanguage, timezone: selectedTimezone })

        setSelectedImage(image)
        setSelectedFile(image)
    }, [name])

    const onSubmit = async data => {
        try {
            const slug = getSlugString(data.name)
            const isSameFile = image === selectedImage

            data.slug = slug
            data._method = 'PUT'

            if (!isSameFile) {
                data.file = selectedFile
            }

            await dispatch(updateChain(data, chainDetails.id))
            showSuccessMessage('notification.chainUpdated')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const hasDeleteChainAccess = checkAccess(
        user?.permissions,
        UserPermissionsEnum.CHAIN_DELETE
    )

    const onChainDelete = async () => {
        try {
            await dispatch(deleteChain(id))
            showSuccessMessage('notification.chainDeleted')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    if (!name) {
        return <SidebarLayout loading={loading} />
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={name} />

            <Form
                {...{
                    errors,
                    control,
                    formConfig: CreateChainFormConfig,
                    isDisabled: isSizeError,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                }}
            >
                <ImagePicker
                    {...{
                        label: 'form.logo',
                        isSizeError,
                        setSizeError,
                        selectedImage,
                        setSelectedFile,
                        setSelectedImage,
                    }}
                />
            </Form>

            {hasDeleteChainAccess && (
                <Box marginTop={2}>
                    <UnderlinedButton
                        onClick={onChainDelete}
                        translationKey={'chain.delete'}
                    />
                </Box>
            )}
        </SidebarLayout>
    )
}

export default withConnectedStore(EditChain)
