import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { useEffect } from 'react'
import { loadChains } from '@store/reducers/chains'
import { ChainTable } from './components/ChainTable'

const Clinics = ({ store, dispatch }) => {
    const { chains, loading } = store.chains
    const userPermissions = store.auth.user?.permissions

    useEffect(() => {
        dispatch(loadChains())
    }, [])

    return (
        <SidebarLayout loading={loading}>
            <ChainTable {...{ chains, userPermissions }} />
        </SidebarLayout>
    )
}

export default withConnectedStore(Clinics)
