import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/Form/Form'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { Routes } from '@shared/constants/routes'
import { useHistory, useParams } from 'react-router'
import { CreateRegionFormConfig } from './configurations/CreateRegionFormConfig'
import { CreateRegionValidator } from './configurations/RegionFormValidators'
import { createRegion } from '@store/reducers/regions'

const CreateRegion = ({ dispatch, store }) => {
    const history = useHistory()
    const { slug } = useParams()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CreateRegionValidator),
    })

    const { loading } = store.regions

    const onSubmit = async data => {
        try {
            await dispatch(createRegion(data, slug))
            showSuccessMessage('notification.regionCreated')

            history.push(Routes.clinics)
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('region.createRegion')} />

            <Form
                {...{
                    errors,
                    control,
                    isDisabled: false,
                    fixedBtnWidth: true,
                    buttonText: translate('save'),
                    onConfirm: handleSubmit(onSubmit),
                    formConfig: CreateRegionFormConfig,
                }}
            />
        </SidebarLayout>
    )
}

export default withConnectedStore(CreateRegion)
