import styled from '@emotion/styled'

const SelectSection = styled.div`
    width: 300px;
    margin-top: 16px;
`

const CSVTitle = styled.h5`
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: normal;
`

const CSVBtnWrapper = styled.div`
    margin-top: 16px;
    max-width: 150px;
`

export { CSVTitle, CSVBtnWrapper, SelectSection }
