import React from 'react'
import { translate } from '@utils/translationUtils'
import { UnderlinedBtn } from './styledComponents'

export const UnderlinedButton = ({
    type = 'button',
    onClick,
    translationKey,
}) => (
    <UnderlinedBtn type={type} onClick={onClick}>
        {translate(translationKey)}
    </UnderlinedBtn>
)
