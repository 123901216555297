export const UserPermissionsEnum = {
    USERS_READ: 'users_read',
    CHAIN_USERS_READ: 'chain_users_read',
    CHAIN_USERS_ADD: 'chain_users_add',
    CHAIN_USERS_DELETE: 'chain_users_delete',
    USER_READ: 'user_read',
    CLINIC_USERS_UPDATE: 'clinic_users_update',
    USER_DELETE: 'user_delete',
    CHAIN_DELETE: 'chain_delete',
    CHAIN_CREATE: 'chain_create',
    CHAIN_UPDATE: 'chain_update',
    REGION_CREATE: 'region_create',
    REGION_UPDATE: 'region_update',
    CLINIC_CREATE: 'clinic_create',
}
