import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { RegionsUrlProvider } from '@store/apiUrlProviders/RegionsUrlProvider'
import { createAction } from '@reduxjs/toolkit'
import { baseActions } from '@store/baseActions'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { getEncodedFormData } from '@utils/requestUtils'
import { baseReducer } from '@store/baseReducers'

const regionsUrlProvider = new RegionsUrlProvider()
const authorizationProvider = new AuthorizationProvider()

const initialState = {
    loading: false,
    regionDetails: {},
    regions: [],
}

// ACTIONS
export const createRegion = createAction(
    'CREATE_REGION',
    (data, chainSlug) => ({
        payload: Axios.post(
            regionsUrlProvider.createRegion(chainSlug),
            getEncodedFormData(data),
            authorizationProvider.getAuthHeaders({ urlencoded: true })
        ),
    })
)

export const editRegion = createAction('EDIT_REGION', (data, id) => ({
    payload: Axios.put(
        regionsUrlProvider.regionById(id),
        getEncodedFormData(data),
        authorizationProvider.getAuthHeaders({ urlencoded: true })
    ),
}))

export const deleteRegion = createAction('DELETE_REGION', id => ({
    payload: Axios.delete(
        regionsUrlProvider.regionById(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const getRegionDetails = createAction('GET_REGION_DETAILS', id => ({
    payload: Axios.get(
        regionsUrlProvider.regionById(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const getRegions = createAction('GET_REGIONS', () => ({
    payload: Axios.get(
        regionsUrlProvider.regions(),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const getChainRegions = createAction('GET_CHAIN_REGIONS', slug => ({
    payload: Axios.get(
        regionsUrlProvider.createRegion(slug),
        authorizationProvider.getAuthHeaders()
    ),
}))

// REDUCERS
const reducer = {
    [editRegion]: baseReducer,
    [createRegion]: baseReducer,
    [getRegionDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            regionDetails: payload.data,
            loading: false,
        }),
    },
    [getRegions]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            loading: false,
            regions: payload.data,
        }),
    },
    [getChainRegions]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            loading: false,
            regions: payload.data,
        }),
    },
}

export default typeToReducer(reducer, initialState)
