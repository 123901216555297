import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import { getReducers } from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { persistStore } from 'redux-persist'

const history = createBrowserHistory()

const configureStore = (history, initialState) => {
    const middleware = [thunk, promise, routerMiddleware(history)]

    const rootReducer = getReducers(history)

    const composedEnhancers = composeWithDevTools(
        compose(applyMiddleware(...middleware))
    )

    return createStore(rootReducer, initialState, composedEnhancers)
}

const createPersistor = store => persistStore(store)

export { history, configureStore, createPersistor }
