import React from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'

const ClinicSettings = () => {
    return (
        <SidebarLayout>
            <h1>Clinic Settings</h1>
        </SidebarLayout>
    )
}

export default ClinicSettings
