import { string, object } from 'yup'

const userValidators = {
    name: string().required('Name is required'),
    status: object()
        .shape({
            label: string().required('Status is required'),
            value: string().required('Status is required'),
        })
        .typeError('Status is required'),
}

export const CreateUserValidator = object().shape({
    ...userValidators,
    email: string().required('Email is required'),
})

export const EditUserValidator = object().shape(userValidators)
