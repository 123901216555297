import React, { useEffect, useRef } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { translate } from '@utils/translationUtils'
import { Routes } from '@shared/constants/routes'
import { useParams } from 'react-router'
import { StyledTitle } from '@containers/clinics/styledComponents'
import Box from '@material-ui/core/Box'
import { getChainDetails } from '@store/reducers/chains'
import Select from 'react-select'
import { getRouteWithSlug } from '@utils/routeUtils'
import { envConfig } from '@infrastructure/envConfig'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import Axios from 'axios'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'
import { CSVBtnWrapper, CSVTitle, SelectSection } from './styledComponents'

const Chain = ({ store, dispatch }) => {
    const { slug } = useParams()

    const { chainDetails, loading } = store.chains
    const { id, name, image } = chainDetails
    const selectRef = useRef(null)

    useEffect(() => {
        dispatch(getChainDetails(slug))
    }, [])

    if (!id) {
        return <SidebarLayout loading={loading} />
    }

    const periodPickerOptions = [
        { value: '7d', label: '7 days' },
        { value: '30d', label: '30 days' },
        { value: '3m', label: '3 months' },
        { value: '6m', label: '6 months' },
    ]

    const authorizationProvider = new AuthorizationProvider()

    const downloadCSV = async () => {
        const periodOption = selectRef.current.state.value

        Axios({
            url: `${envConfig.API_URL}/chains/opus-chain/export_marketing_approve?period=${periodOption.value}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${authorizationProvider.getToken()}`,
            },
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'patients.csv')
            document.body.appendChild(link)
            link.click()
        })
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('chain.chainInfo')}
                navigateTo={getRouteWithSlug(Routes.editChain, slug)}
                navBtnText={translate('chain.edit')}
            />

            <Box display="flex" justifyContent="space-between">
                <Box marginRight={4}>
                    <StyledTitle semibold>{name}</StyledTitle>

                    <SelectSection>
                        <CSVTitle>{translate('chain.getCSV')}</CSVTitle>
                        <Select
                            ref={selectRef}
                            className="booking-select"
                            classNamePrefix="custom-select"
                            options={periodPickerOptions}
                            defaultValue={periodPickerOptions[0]}
                            isSearchable={false}
                        />

                        <CSVBtnWrapper>
                            <PrimaryButton
                                text={translate('chain.downloadCSV')}
                                onClick={downloadCSV}
                            />
                        </CSVBtnWrapper>
                    </SelectSection>
                </Box>

                {image && (
                    <img
                        src={encodeURI(image)}
                        loading="lazy"
                        alt="logo"
                        width={450}
                    />
                )}
            </Box>
        </SidebarLayout>
    )
}

export default withConnectedStore(Chain)
