import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '@shared/constants/colors'
import { appBarHeight, sidebarWidth } from '@shared/constants/styleConstants'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'

const StyledNavLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 15px 10px 25px;
    text-decoration: none;
    color: ${Colors.gray[300]};
    font-size: 20px;
    &.activeLink {
        color: ${Colors.primary};
        position: relative;
        &::before {
            position: absolute;
            display: block;
            content: '';
            left: 0;
            height: 32px;
            width: 9px;
            background-color: ${Colors.primary};
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
`

const NavTitle = styled.span`
    margin-left: 10px;
`

const SidebarWrapper = styled.div`
    display: flex;
`

const DrawerContainer = styled.div`
    overflow: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${Colors.gray[100]};
`

const LinkContent = styled.div`
    display: flex;
    align-items: center;
`

const Content = styled.main`
    flex-grow: 1;
    padding: 24px;
`

const NavSubtitle = styled.span`
    display: block;
    color: ${Colors.gray[250]};
    margin-left: 30px;
`

const StyledToolbar = styled(Toolbar)`
    background-color: ${Colors.gray[100]};
    border-bottom: 2px solid ${Colors.gray[150]};
    height: ${appBarHeight}px;
`

const sidebarStyles = makeStyles(() => ({
    drawer: {
        width: sidebarWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarWidth,
    },
}))

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding: 10px 0;
    margin-bottom: 25px;
`

const SidebarPageWrapper = styled.div`
    max-width: 1000px;
    min-height: 80vh;
    padding: 15px 0;
`

export {
    Content,
    NavTitle,
    LinkContent,
    NavSubtitle,
    StyledToolbar,
    HeaderWrapper,
    sidebarStyles,
    StyledNavLink,
    SidebarWrapper,
    DrawerContainer,
    SidebarPageWrapper,
}
