import { envConfig } from '@infrastructure/envConfig'

export class PractitionersUrlProvider {
    baseUrl = `${envConfig.API_URL}`

    practitionerDetailsUrl = id => `${this.baseUrl}/practitioners/${id}`

    practitionersUrl = clinicId =>
        `${this.baseUrl}/clinics/${clinicId}/practitioners `

    updatePractitionerOrderUrl = clinicId =>
        `${this.baseUrl}/clinics/${clinicId}/practitioners/updateOrder`
}
