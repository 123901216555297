export const Routes = {
    clinics: '/clinics',
    createChain: '/clinics/create',
    viewChain: '/clinics/:slug',
    editChain: '/clinics/edit/:slug',

    createRegion: '/clinic/:slug/create-region',
    editRegion: '/clinic/edit-region/:regionId',

    clinic: '/clinic/:id',
    editClinic: '/clinic/:id/edit',
    createClinic: '/clinic/:slug/create-clinic',

    users: '/users',
    addUser: '/users/create',
    editUser: '/users/edit/:userId',

    treatments: '/treatments/:id',
    treatmentsCreate: '/treatments/:id/create',
    treatmentsEdit: '/treatments/:id/edit/:treatmentId',
    treatmentsCategories: '/treatments/:id/categories',
    treatmentsCategoryCreate: '/treatments/:id/categories/create',
    treatmentsCategoryEdit: '/treatments/:id/categories/edit/:categoryId',

    practitioners: '/practitioners/:id',
    practitionersCreate: '/practitioners/:id/create',
    practitionersEdit: '/practitioners/:id/edit/:practitionerId',

    accountSettings: '/account-settings',
    settings: '/settings/:id',
    profile: '/profile',

    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
}
