import styled from '@emotion/styled'
import { Colors } from '@shared/constants/colors'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: ${props => (props.rightalign ? 'flex-end' : 'flex-start')};
    color: ${Colors.gray[300]};
`

const StyledLinkText = styled.span`
    margin-right: 5px;
`

export { StyledLink, StyledLinkText }
