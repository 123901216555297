import React, { useEffect } from 'react'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { translate } from '@utils/translationUtils'
import { Box, Grid } from '@material-ui/core'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'
import { FormContainer } from '@containers/createChain/styledComponents'
import { TrueFalseEnum } from '@enums/TrueFalseEnum'
import { TreatmentFormDataProvider } from 'providers/treatments/TreatmentFormDataProvider'
import { updateTreatment } from '@store/reducers/treatments'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { getCreateTreatmentFormConfig } from '@containers/createTreatment/configurations/CreateTreatmentFormConfig'
import { CreateTreatmentFromSection } from '@containers/createTreatment/components/CreateTreatmentFromSection'
import { findSelectOptionByValue, getTrueFalseOptions } from '@utils/arrayUtils'
import { DeleteTreatmentBlock } from './DeleteTreatmentBlock'
import { withTreatmentTips } from '@hocs/withTreatmentTips'

const EditTreatmentForm = ({
    reset,
    errors,
    control,
    register,
    dispatch,
    addNewTip,
    removeTip,
    categories,
    treatmentId,
    needShowTips,
    handleSubmit,
    treatmentTips,
    maxTipsLength,
    treatmentDetails,
    setTreatmentTips,
    redirectOnTreatments,
}) => {
    const {
        tips,
        title,
        price,
        category,
        duration,
        show_price,
        opus_dental_id,
        long_description,
        short_description,
    } = treatmentDetails

    const defaultValues = {
        title,
        price,
        duration,
        opus_dental_id,
        long_description,
        short_description,
        opusDentalTreatmentID: '',
        showTips: findSelectOptionByValue(
            getTrueFalseOptions(),
            tips?.length ? TrueFalseEnum.YES : TrueFalseEnum.NO
        ),
        show_price: findSelectOptionByValue(getTrueFalseOptions(), show_price),
    }

    if (category) {
        defaultValues.category = { value: category.id, label: category.name }
    }

    const treatmentFormDataProvider = new TreatmentFormDataProvider()

    const onSubmit = async data => {
        try {
            const formData = treatmentFormDataProvider.generateFormDataWithTipIds(
                data,
                treatmentTips
            )

            await dispatch(updateTreatment(formData, treatmentId))

            showSuccessMessage('notification.treatmentUpdated')
            redirectOnTreatments()
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const CreateTreatmentFormConfig = getCreateTreatmentFormConfig(categories)

    useEffect(() => {
        const formattedTips = treatmentFormDataProvider.formatTipsFromBE(tips)
        const defaultTipsValues = treatmentFormDataProvider.setTipsToDefaultFormValues(
            tips,
            formattedTips
        )

        reset({ ...defaultValues, ...defaultTipsValues })

        if (formattedTips.length) {
            setTreatmentTips(formattedTips)
        } else {
            setTreatmentTips(CreateTreatmentFormConfig.tips)
        }
    }, [treatmentDetails])

    return (
        <FormContainer fullwidth={true}>
            <form>
                <Grid container spacing={1}>
                    <Box display="inline-block" width="45%" mr={5}>
                        <CreateTreatmentFromSection
                            {...{ errors, control, register }}
                            config={CreateTreatmentFormConfig.treatmentInfo}
                        />
                    </Box>

                    <Box display="inline-block" width="45%">
                        <CreateTreatmentFromSection
                            {...{ errors, control, register }}
                            config={CreateTreatmentFormConfig.showTips}
                        />

                        {needShowTips && (
                            <CreateTreatmentFromSection
                                {...{ errors, control, register }}
                                config={treatmentTips}
                                removeTip={removeTip}
                            />
                        )}

                        {needShowTips &&
                            treatmentTips.length !== maxTipsLength && (
                                <Box mt={2} width="150px">
                                    <PrimaryButton
                                        text={translate('treatment.addTip')}
                                        onClick={addNewTip}
                                        disabled={false}
                                    />
                                </Box>
                            )}
                    </Box>
                </Grid>

                <Box mt={10} width="150px">
                    <PrimaryButton
                        text={translate('save')}
                        onClick={handleSubmit(onSubmit)}
                        disabled={false}
                    />
                </Box>

                <DeleteTreatmentBlock
                    {...{ dispatch, treatmentId, redirectOnTreatments }}
                />
            </form>
        </FormContainer>
    )
}

const EditTreatmentFormWithStore = withConnectedStore(
    withTreatmentTips(EditTreatmentForm)
)

export { EditTreatmentFormWithStore as EditTreatmentForm }
