import { TextArea } from '@shared/components/StyledInput/styledComponents'
import { getKey, getTrueFalseOptions } from '@utils/arrayUtils'
import { translate } from '@utils/translationUtils'
import Select from 'react-select'

const trueFalseOptions = getTrueFalseOptions()

export const getCreateTreatmentFormConfig = (categories = []) => {
    const categoryOptions = [
        { id: '', name: translate('treatment.noCategory') },
        ...categories,
    ].map(({ id, name }) => ({
        value: id,
        label: name,
    }))

    return {
        treatmentInfo: [
            {
                id: 0,
                label: translate('treatment.title'),
                fieldProps: {
                    name: 'title',
                    placeholder: 'treatment.enterTitle',
                    required: true,
                },
            },
            {
                id: 1,
                label: translate('treatment.opusDentalTreatmentID'),
                fieldProps: {
                    name: 'opus_dental_id',
                    placeholder: 'treatment.enterOpusDentalTreatmentID',
                    required: true,
                },
            },
            {
                id: 2,
                label: translate('treatment.price'),
                fieldProps: {
                    name: 'price',
                    placeholder: 'treatment.enterPrice',
                    required: true,
                },
            },
            {
                id: 3,
                label: translate('treatment.shortDescription'),
                fieldProps: {
                    name: 'short_description',
                    placeholder: 'treatment.enterShortDescription',
                    required: true,
                    as: TextArea,
                },
            },
            {
                id: 4,
                label: translate('treatment.longDescription'),
                fieldProps: {
                    name: 'long_description',
                    placeholder: 'treatment.enterLongDescription',
                    required: true,
                    as: TextArea,
                },
            },
            {
                id: 51,
                label: translate('treatment.category'),
                element: Select,
                fieldProps: {
                    defaultValue: categoryOptions[0],
                    name: 'category',
                    placeholder: 'treatment.selectOption',
                    required: true,
                    className: 'booking-select',
                    classNamePrefix: 'custom-select',
                    options: categoryOptions,
                    isSearchable: false,
                },
            },
            {
                id: 5,
                label: translate('treatment.showPrice'),
                element: Select,
                fieldProps: {
                    defaultValue: trueFalseOptions[0],
                    name: 'show_price',
                    placeholder: 'treatment.selectOption',
                    required: true,
                    className: 'booking-select',
                    classNamePrefix: 'custom-select',
                    options: trueFalseOptions,
                    isSearchable: false,
                },
            },
            {
                id: 6,
                label: translate('treatment.duration'),
                fieldProps: {
                    name: 'duration',
                    placeholder: 'treatment.enterDuration',
                    required: true,
                },
            },
        ],
        showTips: [
            {
                id: 0,
                label: translate('treatment.showTips'),
                element: Select,
                fieldProps: {
                    defaultValue: trueFalseOptions[1],
                    name: 'showTips',
                    placeholder: 'treatment.selectOption',
                    required: true,
                    className: 'booking-select',
                    classNamePrefix: 'custom-select',
                    options: trueFalseOptions,
                    isSearchable: false,
                },
            },
        ],
        tips: [
            {
                id: getKey(),
                label: translate('treatment.tipHeader'),
                isTip: true,
                tipNumber: 1,
                fieldProps: {
                    name: 'tipHeader',
                    placeholder: 'treatment.enterTipHeader',
                    required: true,
                },
            },
            {
                id: getKey(),
                label: translate('treatment.tipText'),
                isTip: true,
                tipNumber: 1,
                fieldProps: {
                    name: 'tipText',
                    placeholder: 'treatment.enterTipText',
                    required: true,
                    as: TextArea,
                },
            },
        ],
    }
}
