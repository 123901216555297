import { toast } from 'react-toastify'
import { translate } from './translationUtils'

const toastConfig = {
    pauseOnHover: false,
}

const showErrorMessage = (error = {}, message) => {
    const isDevelopmentMode = process.env.NODE_ENV === 'development'
    const errorData = error.response?.data

    const errorFromBE =
        errorData?.errors && Object.values(errorData.errors)[0][0]

    const defaultMessage = 'notification.somethingWentWrong'

    toast.error(translate(message || errorFromBE || defaultMessage))

    if (isDevelopmentMode) {
        console.log(error)
    }
}

const showSuccessMessage = message => toast.success(translate(message))

export { toastConfig, showErrorMessage, showSuccessMessage }
