import { translate } from '@utils/translationUtils'

export const ProfileFormConfig = [
    {
        id: 0,
        label: translate('profile.name'),
        fieldProps: {
            name: 'name',
            placeholder: 'profile.enterName',
        },
    },
    {
        id: 1,
        label: translate('profile.email'),
        message: translate('profile.emailMessage'),
        fieldProps: {
            disabled: true,
            name: 'email',
            placeholder: 'profile.enterEmail',
        },
    },
    {
        id: 2,
        label: translate('profile.newPassword'),
        fieldProps: {
            name: 'password',
            placeholder: 'profile.enterNewPassword',
            type: 'password',
        },
    },
    {
        id: 3,
        label: translate('profile.confirmPassword'),
        fieldProps: {
            name: 'password_confirmation',
            placeholder: 'profile.confirmPassword',
            type: 'password',
        },
    },
]
