import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { Routes } from '@shared/constants/routes'
import { getRouteWithId, getRouteWithCategoryId } from '@utils/routeUtils'
import { translate } from '@utils/translationUtils'
import {
    TableContainer,
    TableContentContainer,
} from '@shared/components/Table/styledComponents'
import { Row } from '@shared/components/Table/Raw'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Title } from '@containers/clinics/components/Title'
import Box from '@material-ui/core/Box'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { getCategories } from '@store/reducers/treatments'
import { useHistory, useParams } from 'react-router'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'

const Categories = ({ store, dispatch }) => {
    const { id } = useParams()
    const history = useHistory()

    const redirectToTreatments = () =>
        history.push(getRouteWithId(Routes.treatments, id))

    const { loading, categories } = store.treatments

    useEffect(() => {
        dispatch(getCategories(id))
    }, [])

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('treatment.categories')}
                navigateTo={getRouteWithId(Routes.treatmentsCategoryCreate, id)}
                navBtnText={translate('treatment.addCategory')}
            />

            <TableContainer>
                <TableContentContainer>
                    {!!categories.length &&
                        categories.map(category => {
                            const { name } = category

                            return (
                                <Box key={category.id} width="100%">
                                    <Row
                                        firstCellContent={
                                            <Title text={name} isSemiBold />
                                        }
                                        forthCellContent={
                                            <UnderlinedLink
                                                text={translate('edit')}
                                                url={getRouteWithCategoryId(
                                                    Routes.treatmentsCategoryEdit,
                                                    category.id,
                                                    id
                                                )}
                                                rightAlign="right"
                                            />
                                        }
                                        marginTop={1}
                                    />
                                </Box>
                            )
                        })}

                    <Box mt={5} width="150px">
                        <PrimaryButton
                            text={translate('return')}
                            onClick={redirectToTreatments}
                            disabled={false}
                        />
                    </Box>
                </TableContentContainer>
            </TableContainer>
        </SidebarLayout>
    )
}

export default withConnectedStore(Categories)
