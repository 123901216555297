import React from 'react'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Title } from './Title'
import { NewWindow } from '@shared/svg/index'
import { Row } from '@shared/components/Table/Raw'
import { translate } from '@utils/translationUtils'
import { getRouteWithId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'

export const ClinicsContent = ({ clinics }) => {
    if (clinics.length) {
        return clinics.map(clinic => {
            const { image, name, practitionersCount, id } = clinic

            return (
                <Row
                    key={id}
                    firstCellContent={
                        <Title text={name} imageSrc={image} isSemiBold />
                    }
                    secondCellContent={`${
                        practitionersCount || 0
                    } practitioners`}
                    thirdCellContent={
                        <UnderlinedLink
                            text={translate('bookingPage')}
                            url="/booking-page"
                            svgIcon={NewWindow}
                        />
                    }
                    forthCellContent={
                        <UnderlinedLink
                            text={translate('view')}
                            url={getRouteWithId(Routes.clinic, id)}
                            rightAlign="right"
                        />
                    }
                    marginTop={1}
                />
            )
        })
    }

    return null
}
