import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { getTreatmentDetails } from '@store/reducers/treatments'
import { useParams } from 'react-router'
import { EditTreatmentForm } from './components/EditTreatmentForm'

const EditTreatment = ({ store, dispatch }) => {
    const { treatmentId } = useParams()
    const { loading, treatmentDetails } = store.treatments

    useEffect(() => {
        dispatch(getTreatmentDetails(treatmentId))
    }, [])

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('treatment.edit')} />

            <EditTreatmentForm {...{ treatmentDetails }} />
        </SidebarLayout>
    )
}

export default withConnectedStore(EditTreatment)
