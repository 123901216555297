import React, { useEffect, useState } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { translate } from '@utils/translationUtils'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    deletePractitioner,
    updatePractitioner,
    getPractitionerDetails,
} from '@store/reducers/practitioners'
import { useHistory, useParams } from 'react-router'
import { Routes } from '@shared/constants/routes'
import { getRouteWithId } from '@utils/routeUtils'
import { PractitionerFormValidators } from '@containers/createPractitioner/configurations/PractitionerFormValidators'
import { PractitionerForm } from '@containers/createPractitioner/components/PractitionerForm'
import { findSelectOptionByValue } from '@utils/arrayUtils'
import { statusOptions } from '@containers/createUser/configurations/CreateUserFormConfig'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import Box from '@material-ui/core/Box'

const EditPractitioner = ({ store, dispatch }) => {
    const { loading, practitionerDetails } = store.practitioners

    const history = useHistory()
    const { id, practitionerId } = useParams()

    const {
        name,
        image,
        title,
        status,
        education,
        biography,
        opus_clinician_id,
    } = practitionerDetails

    const defaultValues = {
        name,
        title,
        status,
        education,
        biography,
        opus_clinician_id,
        status: findSelectOptionByValue(statusOptions, status),
    }

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(PractitionerFormValidators),
    })

    const redirectToPractitioners = () =>
        history.push(getRouteWithId(Routes.practitioners, id))

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isSizeError, setSizeError] = useState(false)

    const onSubmit = async data => {
        try {
            data._method = 'PUT'

            const isSameFile = image === selectedImage

            if (!isSameFile) {
                data.file = selectedFile
            }

            await dispatch(updatePractitioner(data, practitionerId))

            redirectToPractitioners()
            showSuccessMessage('notification.practitionerUpdated')
        } catch (error) {
            showErrorMessage(error)
        }
    }

    useEffect(() => {
        dispatch(getPractitionerDetails(practitionerId))
    }, [])

    useEffect(() => {
        reset(defaultValues)

        setSelectedImage(image)
        setSelectedFile(image)
    }, [practitionerDetails])

    const onChainDelete = async () => {
        try {
            await dispatch(deletePractitioner(practitionerDetails.id))

            showSuccessMessage('notification.practitionerDeleted')
            redirectToPractitioners()
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader title={translate('practitioner.edit')} />

            <PractitionerForm
                {...{
                    errors,
                    control,
                    onSubmit,
                    isSizeError,
                    setSizeError,
                    handleSubmit,
                    selectedImage,
                    setSelectedImage,
                    setSelectedFile,
                }}
            />

            <Box marginTop={2}>
                <UnderlinedButton
                    onClick={onChainDelete}
                    translationKey={'practitioner.delete'}
                />
            </Box>
        </SidebarLayout>
    )
}

export default withConnectedStore(EditPractitioner)
