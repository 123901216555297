import React from 'react'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'
import { translate } from '@utils/translationUtils'
import { getCreateTreatmentFormConfig } from '../configurations/CreateTreatmentFormConfig'
import { Box, Grid } from '@material-ui/core'
import { PrimaryButton } from '@shared/components/Buttons/PrimaryButton'
import { FormContainer } from '@containers/createChain/styledComponents'
import { CreateTreatmentFromSection } from './CreateTreatmentFromSection'
import { TreatmentFormDataProvider } from 'providers/treatments/TreatmentFormDataProvider'
import { createTreatment } from '@store/reducers/treatments'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { withTreatmentTips } from '@hocs/withTreatmentTips'

const CreateTreatmentForm = ({
    id,
    errors,
    control,
    register,
    dispatch,
    addNewTip,
    removeTip,
    categories,
    needShowTips,
    handleSubmit,
    treatmentTips,
    maxTipsLength,
    redirectOnTreatments,
}) => {
    const treatmentFormDataProvider = new TreatmentFormDataProvider()

    const onSubmit = async data => {
        try {
            const formData = treatmentFormDataProvider.generateFormData(data)

            await dispatch(createTreatment(formData, id))

            showSuccessMessage('notification.treatmentCreated')
            redirectOnTreatments()
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const CreateTreatmentFormConfig = getCreateTreatmentFormConfig(categories)

    return (
        <FormContainer fullwidth={true}>
            <form>
                <Grid container spacing={1}>
                    <Box display="inline-block" width="45%" mr={5}>
                        <CreateTreatmentFromSection
                            {...{ errors, control, register }}
                            config={CreateTreatmentFormConfig.treatmentInfo}
                        />
                    </Box>

                    <Box display="inline-block" width="45%">
                        <CreateTreatmentFromSection
                            {...{ errors, control, register }}
                            config={CreateTreatmentFormConfig.showTips}
                        />

                        {needShowTips && (
                            <CreateTreatmentFromSection
                                {...{ errors, control, register }}
                                config={treatmentTips}
                                removeTip={removeTip}
                            />
                        )}

                        {needShowTips &&
                            treatmentTips.length !== maxTipsLength && (
                                <Box mt={2} width="150px">
                                    <PrimaryButton
                                        text={translate('treatment.addTip')}
                                        onClick={addNewTip}
                                        disabled={false}
                                    />
                                </Box>
                            )}
                    </Box>
                </Grid>

                <Box mt={10} width="150px">
                    <PrimaryButton
                        text={translate('save')}
                        onClick={handleSubmit(onSubmit)}
                        disabled={false}
                    />
                </Box>
            </form>
        </FormContainer>
    )
}

const CreateTreatmentFormWithStore = withConnectedStore(
    withTreatmentTips(CreateTreatmentForm)
)

export { CreateTreatmentFormWithStore as CreateTreatmentForm }
