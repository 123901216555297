import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { baseActions } from '@store/baseActions'
import { baseReducer } from '@store/baseReducers'
import { TreatmentsUrlProvider } from '@store/apiUrlProviders/TreatmentsUrlProvider'
import { getFormData, getJSONData } from '@utils/requestUtils'
import { TrueFalseEnum } from '@enums/TrueFalseEnum'

const treatmentsUrlProvider = new TreatmentsUrlProvider()
const authorizationProvider = new AuthorizationProvider()

const initialState = {
    loading: false,
    treatments: [],
    treatmentDetails: {
        tips: [],
        title: '',
        price: '',
        show_price: TrueFalseEnum.YES,
        long_description: '',
        short_description: '',
    },
    categories: [],
    categoryDetails: {},
}

// ACTIONS
export const getClinicTreatments = createAction(
    'GET_CLINIC_TREATMENTS',
    clinicId => ({
        payload: Axios.get(
            treatmentsUrlProvider.treatmentsUrl(clinicId),
            authorizationProvider.getAuthHeaders()
        ),
    })
)

export const createTreatment = createAction(
    'CREATE_TREATMENT',
    (data, clinicId) => {
        const formData = getJSONData(data)

        return {
            payload: Axios.post(
                treatmentsUrlProvider.treatmentsUrl(clinicId),
                formData,
                authorizationProvider.getAuthHeaders({ defaultType: true })
            ),
        }
    }
)

export const getTreatmentDetails = createAction(
    'GET_TREATMENT_DETAILS',
    id => ({
        payload: Axios.get(
            treatmentsUrlProvider.treatmentDetailsUrl(id),
            authorizationProvider.getAuthHeaders()
        ),
    })
)

export const updateTreatment = createAction('UPDATE_TREATMENT', (data, id) => {
    const formData = getJSONData(data)

    return {
        payload: Axios.put(
            treatmentsUrlProvider.treatmentDetailsUrl(id),
            formData,
            authorizationProvider.getAuthHeaders({ defaultType: true })
        ),
    }
})

export const deleteTreatment = createAction('DELETE_TREATMENT', id => ({
    payload: Axios.delete(
        treatmentsUrlProvider.treatmentDetailsUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const getCategories = createAction('GET_CATEGORIES', clinicId => ({
    payload: Axios.get(
        treatmentsUrlProvider.clinicCategoriesUrl(clinicId),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const createCategory = createAction(
    'CREATE_CATEGORY',
    (data, clinicId) => {
        const formData = getFormData(data)

        return {
            payload: Axios.post(
                treatmentsUrlProvider.clinicCategoriesUrl(clinicId),
                formData,
                authorizationProvider.getAuthHeaders()
            ),
        }
    }
)

export const getCategoryDetails = createAction('GET_CATEGORY_DETAILS', id => ({
    payload: Axios.get(
        treatmentsUrlProvider.treatmentCategoryUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const updateCategory = createAction(
    'UPDATE_CATEGORY',
    (data, categoryId) => {
        const formData = getJSONData(data)

        return {
            payload: Axios.put(
                treatmentsUrlProvider.treatmentCategoryUrl(categoryId),
                formData,
                authorizationProvider.getAuthHeaders({ defaultType: true })
            ),
        }
    }
)

export const deleteCategory = createAction('DELETE_CATEGORY', id => ({
    payload: Axios.delete(
        treatmentsUrlProvider.treatmentCategoryUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

export const updateTreatmentCategoryOrder = createAction(
    'UPDATE_TREATMENT_CATEGORY_ORDER',
    (data, clinicId) => {
        const formData = getJSONData(data)

        return {
            payload: Axios.post(
                treatmentsUrlProvider.updateTreatmentCategoryOrderUrl(clinicId),
                formData,
                authorizationProvider.getAuthHeaders({ defaultType: true })
            ),
        }
    }
)

export const updateTreatmentsOrder = createAction(
    'UPDATE_TREATMENTS_ORDER',
    (data, clinicId, categoryId) => {
        const formData = getJSONData(data)

        return {
            payload: Axios.post(
                treatmentsUrlProvider.updateTreatmentsOrderUrl(
                    clinicId,
                    categoryId
                ),
                formData,
                authorizationProvider.getAuthHeaders({ defaultType: true })
            ),
        }
    }
)

export const updateTreatmentCategoryStatus = createAction(
    'UPDATE_TREATMENTS_STATUS',
    (data, clinicId) => {
        const formData = getFormData(data)

        return {
            payload: Axios.post(
                treatmentsUrlProvider.updateTreatmentCategoryStatus(clinicId),
                formData,
                authorizationProvider.getAuthHeaders({ defaultType: true })
            ),
        }
    }
)

// REDUCERS
const reducer = {
    [getClinicTreatments]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            treatments: payload.data.categories,
            loading: false,
        }),
    },
    [createTreatment]: baseReducer,
    [getTreatmentDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            treatmentDetails: payload.data,
            loading: false,
        }),
    },
    [updateTreatment]: baseReducer,
    [deleteTreatment]: baseReducer,
    [createCategory]: baseReducer,
    [updateCategory]: baseReducer,
    [deleteCategory]: baseReducer,
    [getCategories]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            categories: payload.data,
            loading: false,
        }),
    },
    [getCategoryDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            categoryDetails: payload.data,
            loading: false,
        }),
    },
}

export default typeToReducer(reducer, initialState)
