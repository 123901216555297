import { translate } from '@utils/translationUtils'
import { Routes } from '@shared/constants/routes'
import { getRouteWithId } from '@utils/routeUtils'
import {
    ExitIcon,
    UsersIcon,
    ClinicIcon,
    ProfileIcon,
    SettingsIcon,
    TreatmentIcon,
    ClinicSettingsIcon,
} from '@shared/svg/index'
import { UserRolesEnum } from '@enums/UserRolesEnum'

export class NavigationConfig {
    getSidebarTopNavConfig = userRole => {
        const config = [
            {
                title: translate('sidebar.clinics'),
                route: Routes.clinics,
                icon: ClinicIcon,
            },
        ]

        if (userRole !== UserRolesEnum.REGULAR_USER) {
            config.push({
                title: translate('sidebar.users'),
                route: Routes.users,
                icon: UsersIcon,
            })
        }
        
        return config
    }

    getSidebarClinicNavConfig = clinicId => [
        {
            title: translate('sidebar.clinic'),
            route: getRouteWithId(Routes.clinic, clinicId),
            icon: ClinicIcon,
        },
        {
            title: translate('sidebar.treatments'),
            route: getRouteWithId(Routes.treatments, clinicId),
            icon: UsersIcon,
        },
        {
            title: translate('sidebar.practitioners'),
            route: getRouteWithId(Routes.practitioners, clinicId),
            icon: TreatmentIcon,
        },
        {
            title: translate('sidebar.settings'),
            route: getRouteWithId(Routes.settings, clinicId),
            icon: ClinicSettingsIcon,
        },
    ]

    getSidebarBottomNavConfig = (userName, userRole) => [
        {
            title: userName,
            subtitle: userRole,
            route: Routes.profile,
            icon: ProfileIcon,
        },
        {
            title: translate('sidebar.accountSettings'),
            route: Routes.accountSettings,
            icon: SettingsIcon,
        },
        {
            title: translate('sidebar.logout'),
            route: Routes.login,
            icon: ExitIcon,
            isLogout: true,
        },
    ]
}
