import React from 'react'
import { StyledInput } from '@shared/components/StyledInput/StyledInput'
import { Box, Grid } from '@material-ui/core'
import { getKey } from '@utils/arrayUtils'
import { StyledCloseIcon } from '../styledComponents'

export const CreateTreatmentFromSection = ({
    isClinicTip = false,
    config,
    errors,
    control,
    register,
    removeTip,
}) => {
    if (config.length) {
        return config.map((el, index) => {
            const isEven = index % 2
            let needShowRemoveBtn = el.isTip && !isEven && config.length > 2

            if (isClinicTip) {
                needShowRemoveBtn = el.isTip && config.length > 1
            }

            return (
                <Grid item key={getKey()}>
                    <Box position="relative" mt={1}>
                        <StyledInput
                            {...el}
                            {...{
                                errors,
                                control,
                                register,
                            }}
                        />

                        {needShowRemoveBtn && (
                            <StyledCloseIcon
                                onClick={() => removeTip(el.tipNumber)}
                            />
                        )}
                    </Box>
                </Grid>
            )
        })
    }

    return null
}
