import React from 'react'
import { UnderlinedButton } from '@shared/components/Buttons/UnderlinedButton'
import Box from '@material-ui/core/Box'
import { deleteTreatment } from '@store/reducers/treatments'
import { showErrorMessage, showSuccessMessage } from '@utils/notificationUtils'

export const DeleteTreatmentBlock = ({
    dispatch,
    treatmentId,
    redirectOnTreatments,
}) => {
    const onTreatmentDelete = async () => {
        try {
            await dispatch(deleteTreatment(treatmentId))

            showSuccessMessage('notification.treatmentDeleted')
            redirectOnTreatments()
        } catch (error) {
            showErrorMessage(error)
        }
    }

    return (
        <Box marginTop={2}>
            <UnderlinedButton
                onClick={onTreatmentDelete}
                translationKey={'treatment.delete'}
            />
        </Box>
    )
}
