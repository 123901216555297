import React from 'react'
import { Box, Accordion, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Row } from '@shared/components/Table/Raw'
import { Title } from '@containers/clinics/components/Title'
import { translate } from '@utils/translationUtils'
import { UsersContent } from './UsersContent'

export const Users = ({
    users = [],
    columnTitleKey,
    isChainAdmin = false,
    withoutEditing = false,
}) => {
    const renderUsers = () =>
        users?.map(user => (
            <UsersContent
                key={user.id}
                {...user}
                withoutEditing={withoutEditing}
            />
        ))

    if (users.length) {
        if (isChainAdmin) {
            return <Box mb={4}>{renderUsers()}</Box>
        }

        return (
            <Box mb={4}>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Row
                            firstCellContent={
                                <Title text={translate(columnTitleKey)} />
                            }
                        />
                    </AccordionSummary>

                    {renderUsers()}
                </Accordion>
            </Box>
        )
    }

    return null
}
