import React from 'react'
import { TableColumn } from './styledComponents'
import Box from '@material-ui/core/Box'

export const Row = ({
    marginTop = 0,
    firstCellContent,
    secondCellContent,
    thirdCellContent,
    forthCellContent,
    firstCellWidth = 40,
    secondCellWidth = 25,
    thirdCellWidth = 25,
    forthCellWidth = 10,
}) => (
    <Box display="flex" alignItems="center" width="100%" mt={marginTop}>
        <TableColumn width={firstCellWidth}>{firstCellContent}</TableColumn>
        <TableColumn width={secondCellWidth}>{secondCellContent}</TableColumn>
        <TableColumn width={thirdCellWidth}>{thirdCellContent}</TableColumn>
        <TableColumn width={forthCellWidth}>{forthCellContent}</TableColumn>
    </Box>
)
