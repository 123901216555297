import { passwordConfirmationValidator } from '@containers/login/configurations/LoginValidationConfig'
import { object, string } from 'yup'

export const ProfileValidator = object().shape({
    name: string().required('Name is required').max(50).label('Name'),
})

export const passwordWithoutEmail = email => ({
    password: string()
        .min(8)
        .max(16)
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
            'Please choose a stronger password. Try a mix of letters (Uppercase and Lowercase), numbers, and symbols.'
        )
        .test(
            'Should not contain a user email',
            'Should not contain a user email',
            value => !value.includes(email)
        ),
    ...passwordConfirmationValidator,
})

export const ProfileValidatorWithPasswords = email =>
    object().shape({
        name: string().required('Name is required').max(50),
        ...passwordWithoutEmail(email),
    })
